import React from "react";
import { formatDate, formatNumberToCurrency } from "../helpers/helpers";
import { useSelector } from "react-redux";

const TransactionHistory = ({ transactions }) => {
  const loadingTransactions = useSelector(
    (state) => state.auth.loadingTransactions
  );

  const getColor = (status) => {
    if (status === "success") {
      return {
        color: "text-green-400",
        status: "Thành công",
      };
    } else if (status === "wrong") {
      return {
        color: "text-yellow-400",
        status: "Sai mệnh giá",
      };
    } else if (status === "pending") {
      return {
        color: "text-blue-400",
        status: "Chờ duyệt",
      };
    } else {
      return {
        color: "text-red-400",
        status: "Thất bại",
      };
    }
  };
  return (
    <div className="overflow-x-auto">
      <table className="w-full" style={{ borderCollapse: "collapse" }}>
        <thead
          className="sc-jXbUNg fhRPSq"
          style={{
            borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
            textAlign: "center",
          }}
        >
          <tr className="sc-dhKdcB sc-kpDqfm dumyQE jCVLsR">
            <td
              className="sc-jlZhew gsJfOs"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              Trạng thái
            </td>
            <td
              className="sc-jlZhew gsJfOs"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              Xu nhận
            </td>
            <td
              className="sc-jlZhew gsJfOs"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              Loại thẻ
            </td>
            <td
              className="sc-jlZhew gsJfOs"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              Mệnh giá
            </td>
            <td
              className="sc-jlZhew gsJfOs"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              Serial
            </td>
            <td
              className="sc-jlZhew gsJfOs"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              Mã thẻ
            </td>
            <td
              className="sc-jlZhew gsJfOs"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              Thời gian
            </td>
          </tr>
        </thead>
        <tbody
          className="sc-dAlyuH jGnpzp flex-col overflow-auto"
          style={{
            borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
            textAlign: "center",
          }}
        >
          {loadingTransactions || transactions.length <= 0 ? (
            <>
              {" "}
              <tr
                className="sc-dhKdcB dumyQE"
                style={{
                  borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                  textAlign: "center",
                }}
              >
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  <div className="sc-cwHptR dWpIjx" />
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  <div className="sc-cwHptR dWpIjx" />
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  <div className="sc-cwHptR dWpIjx" />
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  <div className="sc-cwHptR dWpIjx" />
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  <div className="sc-cwHptR dWpIjx" />
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  <div className="sc-cwHptR dWpIjx" />
                </td>
              </tr>
            </>
          ) : (
            transactions.map((transaction) => (
              <tr
                className="sc-dhKdcB dumyQE"
                style={{
                  borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                  textAlign: "center",
                }}
              >
                 <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  <span
                    className={`text-sm font-semibold ${
                      getColor(transaction.status).color
                    }`}
                  >
                    {getColor(transaction.status).status}
                  </span>
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  {formatNumberToCurrency(transaction.so_luong_base)} Coin cơ
                  bản
                  {transaction.so_luong - transaction.so_luong_base > 0 && (
                    <>
                      ,{" "}
                      {formatNumberToCurrency(
                        transaction.so_luong - transaction.so_luong_base
                      )}{" "}
                      Coin tặng theo gói
                    </>
                  )}
                  {transaction.promotions > 0 && (
                    <>
                      , {formatNumberToCurrency(transaction.promotions)} Coin
                      nhiệm vụ
                    </>
                  )}
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  {transaction.type}
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  {formatNumberToCurrency(transaction.amount)}
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  {transaction.serial}
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  {transaction.pin}
                </td>
                <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                  {formatDate(transaction.time)}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
export default TransactionHistory;
