import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutPC from "../components/layouts/LayoutPC";
import CoinPage from "../pages/CoinPage";
import Transactions from "../pages/Transactions";

export default function PCRoutes() {
  const [showLogin, setShowLogin] = useState(false);

  return (
    <>
      <Routes>
        <Route path="/coins/transactions" element={<Transactions />} />
        <Route
          path="/coins"
          element={
            <LayoutPC setShowLogin={setShowLogin} showLogin={showLogin}>
              <CoinPage setShowLogin={setShowLogin} />{" "}
            </LayoutPC>
          }
        />
        <Route path="/" element={<Navigate to="/coins" />} />
      </Routes>
    </>
  );
}
