import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutMobile from "../components/layouts/LayoutMobile";
import CoinPageMobile from "../pages/CoinPageMobile";
import SagesslashPage from "../pages/SagesslashPage";

export default function MobileRoutes() {
  const [showLogin, setShowLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [packageSelected, setPackageSelected] = useState({});

  return (
    <Routes>
      <Route
        path="/coins"
        element={
          <LayoutMobile
            setShowLogin={setShowLogin}
            showLogin={showLogin}
            showLogout={showLogout}
            setShowLogout={setShowLogout}
            setPackageSelected={setPackageSelected}
            packageSelected={packageSelected}
          >
            <CoinPageMobile
              setShowLogin={setShowLogin}
              setPackageSelected={setPackageSelected}
              packageSelected={packageSelected}
              setShowLogout={setShowLogout}
            />
          </LayoutMobile>
        }
      />
      <Route path="/" element={<Navigate to="/coins" />} />
    </Routes>
  );
}
