import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { formatNumberToCurrency } from "../helpers/helpers";
import CashierModal from "../components/CashierModal";
import LoginModal from "../components/LoginModal";
import CountdownTimer from "../components/CountdownTimer";
import TransactionHistory from "../components/TransactionHistory";
import ChatBox from "../components/ChatBox";

export default React.memo(function CoinPage({ setShowLogin }) {
  const [showRecharge, setShowRecharge] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const packages = useSelector((state) => state.auth.packages);
  const giftUnUsed = useSelector((state) => state.auth.giftUnUsed);
  const gifts = useSelector((state) => state.auth.gifts);
  const transactions = useSelector((state) => state.auth.transactions);
  const loadingTransactions = useSelector(
    (state) => state.auth.loadingTransactions
  );

  const [totalPromotionsUsed, setTotalPromotionsUsed] = useState(0);

  const [packageSelected, setPackageSelected] = useState({});
  const navivate = useNavigate();

  useEffect(() => {
    setTotalPromotionsUsed(
      gifts.reduce(
        (acc, item) => acc + (item.statusReceived ? item.promotions : 0),
        0
      )
    );
  }, [gifts]);

  const handleChangePackageSelected = (obj) => {
    setPackageSelected(obj);
  };

  const handleShowRecharge = () => {
    if (!isLoggedIn) {
      setShowLogin(true);
      return;
    }
    setShowRecharge(true);
  };

  const handleCloseRecharge = () => {
    setPackageSelected({});
    setShowRecharge(false);
  };

  const getContentGift = () => {
    if (isLoggedIn) {
      if (!giftUnUsed) {
        return `Bạn đã hoàn thành nhiệm vụ nhận ${formatNumberToCurrency(
          totalPromotionsUsed
        )} xu`;
      }
      if (giftUnUsed?.coins < user.balance_base) {
        return `Nạp thẻ với mệnh giá bất kỳ để nhận ${formatNumberToCurrency(
          giftUnUsed.promotions
        )} xu`;
      } else {
        return `Hoàn thành nhiệm vụ để nhận ${formatNumberToCurrency(
          giftUnUsed.promotions
        )} xu`;
      }
    } else {
      return "Cơ hội nhận 1,000 xu hôm nay đăng nhập để biết thêm chi tiết";
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <>
          <div className="flex items-center justify-between">
            <h2 className="font-[700] text-[24px] flex items-center gap-2">
              Nạp Xu Tiktok{" "}
              <img
                src="/images/xu.avif"
                alt="Tiktok-coins"
                width="30"
                height="30"
              />
            </h2>
            {/* <Link to="/coins/transactions" className="font-[700] text-[16px]">
              Xem lịch sử giao dịch
            </Link> */}
          </div>
          <div className="flex space-between">
            <div className="profile-banner-row-t6utkp">
              <div className="profile-info-Q9sNRY">
                <div
                  role="button"
                  aria-controls
                  data-e2e="profile-icon"
                  className="profile-container-PtYMD6"
                  style={{
                    backgroundImage: `url("${user?.avatar}")`,
                    height: 44,
                    width: 44,
                    minWidth: 44,
                  }}
                />
                <div className="name-coin-info-DYBHMR">
                  <span
                    className="name-info-QbqROM"
                    data-e2e="wallet-user-name"
                  >
                    {user?.nickname}
                  </span>
                  <div className="coin-info-7gryus">
                    <svg
                      color="inherit"
                      fontSize={18}
                      viewBox="0 0 48 48"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <circle cx={24} cy={24} r={22} fill="#FBD32C" />
                      <circle cx={24} cy={24} r={17} fill="#F9BA10" />
                      <path
                        d="M40.93 25.5a17 17 0 1 0-33.87 0 17 17 0 0 1 33.87 0Z"
                        fill="#F6A811"
                      />
                      <path
                        d="M33 19a5.03 5.03 0 0 1-5.06-5h-3.38v13.61a3.07 3.07 0 0 1-3.1 3.06c-1.7 0-3.09-1.37-3.09-3.06a3.07 3.07 0 0 1 3.94-2.94v-3.4A6.43 6.43 0 0 0 15 27.6c0 3.54 2.9 6.4 6.47 6.4a6.43 6.43 0 0 0 6.47-6.39v-6.94A8.47 8.47 0 0 0 33 22.33V19Z"
                        fill="#fff"
                      />
                    </svg>
                    <span
                      className="coin-number-qL_YHk"
                      data-e2e="wallet-coins-balance"
                    >
                      {`${formatNumberToCurrency(user.balance)} ${
                        user.balance > 0 ? " - chưa cập nhật vào app" : ""
                      }`}
                    </span>
                  </div>
                </div>
                <div
                  data-e2e="wallet-exchange-entrance"
                  style={{
                    paddingInlineStart: 20,
                    marginInlineStart: 20,
                    borderInlineStart: "solid 1px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div
                    className="flex items-center p-0"
                    style={{
                      fontSize: 14,
                      lineHeight: "22px",
                      cursor: "pointer",
                      background: "none",
                      border: "none",
                      fontWeight: "bold",
                      color: "var(--ui-text-placeholder)",
                    }}
                  >
                    Nhiệm vụ nhận xu
                  </div>
                  <div
                    data-e2e="wallet-revenue"
                    className="flex items-center gap-1"
                    style={{
                      color: "rgba(0, 0, 0, 0.48)",
                      fontSize: 14,
                      lineHeight: "22px",
                      width: "max-content",
                    }}
                  >
                    {giftUnUsed?.coins > user.balance_base
                      ? `Bạn đã hoàn thành: ${formatNumberToCurrency(
                          user.balance_base
                        )}/${formatNumberToCurrency(giftUnUsed?.coins)}`
                      : "Nạp 1 thẻ cào bất kỳ để hoàn thành nhiệm vụ"}
                    <span className="flex items-center gap-1">
                      <svg
                        className="my-0 mx-[2px]"
                        color="inherit"
                        fontSize={12}
                        viewBox="0 0 48 48"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        style={{ verticalAlign: "-0.15em" }}
                      >
                        <circle cx={24} cy={24} r={22} fill="#FBD32C" />
                        <circle cx={24} cy={24} r={17} fill="#F9BA10" />
                        <path
                          d="M40.93 25.5a17 17 0 1 0-33.87 0 17 17 0 0 1 33.87 0Z"
                          fill="#F6A811"
                        />
                        <path
                          d="M33 19a5.03 5.03 0 0 1-5.06-5h-3.38v13.61a3.07 3.07 0 0 1-3.1 3.06c-1.7 0-3.09-1.37-3.09-3.06a3.07 3.07 0 0 1 3.94-2.94v-3.4A6.43 6.43 0 0 0 15 27.6c0 3.54 2.9 6.4 6.47 6.4a6.43 6.43 0 0 0 6.47-6.39v-6.94A8.47 8.47 0 0 0 33 22.33V19Z"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="tiktok-1pfa32v-DivLoginPrompt e1sanywi13"
          onClick={() => setShowLogin(true)}
        >
          <svg
            fontSize="44px"
            viewBox="0 0 48 48"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
          >
            <g clipPath="url(#Icon_Color-Default_Avatar_svg__a)">
              <circle cx={24} cy={24} r={24} fill="#D0D1D3" />
              <circle cx={24} cy={22} r={9} fill="#fff" fillOpacity="0.75" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.28 42.16a18 18 0 0 1 31.37 0A23.9 23.9 0 0 1 23.97 48c-6 0-11.48-2.2-15.69-5.84Z"
                fill="#fff"
                fillOpacity="0.75"
              />
            </g>
            <defs>
              <clipPath id="Icon_Color-Default_Avatar_svg__a">
                <path fill="#fff" d="M0 0h48v48H0z" />
              </clipPath>
            </defs>
          </svg>
          <span className="tiktok-1w6ez19-SpanLoginText e1sanywi14">
            Đăng nhập
          </span>
        </div>
      )}
      {giftUnUsed && isLoggedIn && user.balance_base ? (
        <div
          className="coin-list-title-dqoO1A"
          data-e2e="wallet-buy-coins-title"
        >
          Thông báo:
          <strong
            data-e2e="wallet-recharge-advantage-tip"
            className="recharge-advantage-info-pwKwR0"
          >
            Bạn cần hoàn thành nhiệm vụ trước khi TikTok cập nhật xu vào tài
            khoản của bạn
            <div className="TUXTooltip-reference">
              <span
                className="explanation-container-fNP9gK"
                data-e2e="wallet-recharge-advantage-icon"
              >
                <svg
                  fill="currentColor"
                  color="inherit"
                  fontSize="14px"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  style={{ verticalAlign: "middle" }}
                >
                  <path d="M24 6a18 18 0 1 0 0 36 18 18 0 0 0 0-36ZM2 24a22 22 0 1 1 44 0 22 22 0 0 1-44 0Zm25-8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V23a1 1 0 0 0-1-1h-2Z" />
                </svg>
              </span>
            </div>
          </strong>
        </div>
      ) : (
        <div
          className="coin-list-title-dqoO1A"
          data-e2e="wallet-buy-coins-title"
        >
          Nạp tiền:
          <strong
            data-e2e="wallet-recharge-advantage-tip"
            className="recharge-advantage-info-pwKwR0"
          >
            Hệ thống xử lý thẻ cào nhanh chóng và cộng xu trong vòng 3 giây
            <div className="TUXTooltip-reference">
              <span
                className="explanation-container-fNP9gK"
                data-e2e="wallet-recharge-advantage-icon"
              >
                <svg
                  fill="currentColor"
                  color="inherit"
                  fontSize="14px"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  style={{ verticalAlign: "middle" }}
                >
                  <path d="M24 6a18 18 0 1 0 0 36 18 18 0 0 0 0-36ZM2 24a22 22 0 1 1 44 0 22 22 0 0 1-44 0Zm25-8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V23a1 1 0 0 0-1-1h-2Z" />
                </svg>
              </span>
            </div>
          </strong>
        </div>
      )}

      <div
        className="h-[64px] rounded rounded-lg mt-[12px] flex justify-between cursor-pointer overflow-hidden sagasslash"
        style={{
          background:
            "linear-gradient(90deg,rgb(229, 252, 255),rgb(177, 232, 255) 100%)",
        }}
        // onClick={()=>navivate("/sagesslash")}
      >
        <div
          className="flex flex-col gap-2 items-stretch justify-center"
          style={{ padding: "8px 24px" }}
          onClick={() => !isLoggedIn && setShowLogin(true)}
        >
          <div className="flex items-center">
            <span
              className="text-center font-bold"
              style={{
                fontSize: 18,
                lineHeight: "24px",
              }}
            >
              {getContentGift()}
            </span>
            {!isLoggedIn && (
              <svg
                fill="currentColor"
                className="flip-rtl"
                color="var(--ui-text-1)"
                fontSize={16}
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
              >
                <path d="M28.74 24 15.08 10.33a1 1 0 0 1 0-1.41l1.84-1.84a1 1 0 0 1 1.41 0L34.54 23.3a1 1 0 0 1 0 1.42l-16.2 16.21a1 1 0 0 1-1.42 0l-1.84-1.84a1 1 0 0 1 0-1.41L28.74 24Z" />
              </svg>
            )}
          </div>
          <span
            className="TUXText TUXText--tiktok-sans"
            style={{
              color: "var(--ui-text-1)",
              fontSize: 16,
              lineHeight: "21px",
            }}
          >
            <CountdownTimer />
          </span>
        </div>
        <img
          className="mr-8"
          src="https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/unified-wallet-desktop/sg/static/image/promotion-banner.2bce2c42.png"
          alt="promotion image"
        />
      </div>
      <div style={{ margin: "12px 0px 10px" }}>
        <ol
          id="recharge-coin-list-ol"
          data-e2e="wallet-coins-packages"
          style={{
            padding: 0,
            margin: 0,
            listStyleType: "none",
            width: "100%",
            position: "relative",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, 219px)",
            gap: 24,
            justifyContent: "space-between",
          }}
        >
          {packages.map((obj, index) => (
            <li style={{ overflow: "hidden" }} key={index}>
              <button
                type="button"
                data-e2e="wallet-package-selected"
                aria-label="70 Coins"
                style={{
                  width: "100%",
                  minHeight: 96,
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 4,
                  cursor: "pointer",
                  pointerEvents: "initial",
                  backgroundColor: "transparent",
                  border:
                    obj.amount === packageSelected.amount
                      ? "2px solid rgba(250, 206, 21, 1)"
                      : "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "17px 2px 15px",
                  opacity: 1,
                }}
                onClick={() => handleChangePackageSelected(obj)}
              >
                <div
                  style={{ height: 32, display: "flex", alignItems: "center" }}
                >
                  <span data-e2e="wallet-package-coin-icon-0">
                    <svg
                      color="inherit"
                      fontSize="32px"
                      viewBox="0 0 48 48"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <circle cx={24} cy={24} r={22} fill="#FBD32C" />
                      <circle cx={24} cy={24} r={17} fill="#F9BA10" />
                      <path
                        d="M40.93 25.5a17 17 0 1 0-33.87 0 17 17 0 0 1 33.87 0Z"
                        fill="#F6A811"
                      />
                      <path
                        d="M33 19a5.03 5.03 0 0 1-5.06-5h-3.38v13.61a3.07 3.07 0 0 1-3.1 3.06c-1.7 0-3.09-1.37-3.09-3.06a3.07 3.07 0 0 1 3.94-2.94v-3.4A6.43 6.43 0 0 0 15 27.6c0 3.54 2.9 6.4 6.47 6.4a6.43 6.43 0 0 0 6.47-6.39v-6.94A8.47 8.47 0 0 0 33 22.33V19Z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                  <b
                    data-e2e="wallet-package-coin-num-0"
                    className="H3-Bold"
                    style={{
                      fontFamily:
                        "TikTokDisplayFont, Arial, Tahoma, PingFangSC, sans-serif",
                      fontSize: 24,
                      lineHeight: "1.2",
                      color: "var(--ui-text-1-display)",
                      verticalAlign: "middle",
                      marginLeft: 8,
                      marginRight: 0,
                      fontWeight: 700,
                    }}
                  >
                    {formatNumberToCurrency(obj.coins)}
                  </b>
                  <span className="text-yellow-700">
                    +{formatNumberToCurrency(obj.promotions)}
                  </span>
                </div>
                <div
                  data-e2e="wallet-package-price-0"
                  className="P3-Regular text-color-TextTertiaryAlt"
                  style={{
                    fontFamily:
                      "TikTokFont, Arial, Tahoma, PingFangSC, sans-serif",
                    fontWeight: 700,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.48)",
                    textAlign: "center",
                    lineHeight: "1.1",
                    height: 20,
                    marginTop: 8,
                    display: "flex",
                  }}
                >
                  ₫{formatNumberToCurrency(obj.amount)}
                </div>
              </button>
            </li>
          ))}
        </ol>
      </div>
      <div
        style={{
          height: 22,
          display: "flex",
          alignItems: "center",
          gap: 4,
          marginBottom: 12,
        }}
      >
        <span data-e2e="wallet-title-payment-method">
          Phương thức thanh toán
        </span>
        <img
          className="h-[14px] w-[22px] me-[4px]"
          src="https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/visa_acffbd.png"
          data-e2e="wallet-payment-icon-VISA"
          alt="VISA"
          style={{ display: "inline-block" }}
        />
        <img
          className="h-[14px] w-[22px] me-[4px]"
          src="https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/momo_4256e5.png"
          data-e2e="wallet-payment-icon-MOMO"
          alt="MOMO"
          style={{ display: "inline-block" }}
        />
        <img
          className="h-[14px] w-[22px] me-[4px]"
          src="https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/zalopay_8e254f.png"
          alt="ZALOPAY"
          style={{ display: "inline-block" }}
        />
        <img
          className="h-[14px] me-[4px]"
          src="/images/thecao/viettel.png"
          alt="VIETTEL"
          style={{ display: "inline-block" }}
        />
        <img
          className="h-[14px] me-[4px]"
          src="/images/thecao/mobifone.png"
          alt="MOBIFONE"
          style={{ display: "inline-block" }}
        />
        <img
          className="h-[14px] me-[4px]"
          src="/images/thecao/vinaphone.png"
          alt="VINAPHONE"
          style={{ display: "inline-block" }}
        />
        <img
          className="h-[14px] me-[4px]"
          src="/images/thecao/zing.png"
          alt="ZING"
          style={{ display: "inline-block" }}
        />
        <img
          className="h-[18px] me-[4px]"
          src="/images/thecao/garena.png"
          alt="GARENA"
          style={{ display: "inline-block" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 15,
          gap: 14,
          fontSize: 16,
        }}
      >
        <span data-e2e="wallet-title-total-price">Tổng tiền</span>
        <b data-e2e="wallet-total-price">
          ₫{formatNumberToCurrency(packageSelected.amount)}
        </b>
      </div>
      <div>
        <button
          className="TUXButton TUXButton--default TUXButton--medium TUXButton--primary"
          aria-disabled="false"
          type="button"
          data-e2e="wallet-buy-now-button"
          aria-label="Recharge"
          style={{ width: 200, height: 46 }}
          disabled={Object.keys(packageSelected).length === 0}
          onClick={handleShowRecharge}
        >
          <div className="TUXButton-content">
            <div className="TUXButton-label">Thanh toán</div>
          </div>
        </button>
      </div>

      <div data-e2e="cashier-secure-payment" className="css-1bvc4cc">
        <img
          alt="secure payment icon"
          src="/images/download.svg"
          aria-expanded="false"
          aria-haspopup="dialog"
          style={{ height: 27, width: 65, cursor: "pointer" }}
        />
      </div>
      {isLoggedIn && (
        <div className="mt-4 mb-4">
          <TransactionHistory transactions={transactions} />
        </div>
      )}
      <div className="mt-4 mb-4">
        <ChatBox />
      </div>
      {showRecharge && (
        <CashierModal
          closeRecharge={handleCloseRecharge}
          showRecharge={showRecharge}
          data={packageSelected}
        />
      )}
    </div>
  );
});
