import React from "react";

export default function Loading() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div className="w-[48px] h-[48px] box-border flex items-center justify-center">
        <svg
          fill="currentColor"
          preserveAspectRatio="none"
          viewBox="0 0 463 276"
          width={25}
          height={15}
        >
          <defs>
            <mask id="tux-loading-red-hole-0">
              <rect width="100%" height="100%" fill="rgb(255, 255, 255)" />
              <circle
                r={110}
                cx="352.5"
                cy={138}
                strokeWidth={0}
                className="tux-loading__red_animation"
                fill="rgb(15, 15, 15)"
              />
            </mask>
            <mask id="tux-loading-green-hole-0">
              <rect width="100%" height="100%" fill="rgb(255, 255, 255)" />
              <circle
                r={110}
                cx={111}
                cy={138}
                strokeWidth={0}
                className="tux-loading__green_animation"
                fill="rgb(15, 15, 15)"
              />
            </mask>
          </defs>
          <circle
            r={110}
            cx="352.5"
            cy={138}
            strokeWidth={0}
            className="tux-loading__red_animation"
            fill="rgb(15, 15, 15)"
          />
          <circle
            mask="url(#tux-loading-red-hole-0)"
            r={110}
            cx={111}
            cy={138}
            strokeWidth={0}
            className="tux-loading__green_animation"
            fill="rgb(37, 244, 238)"
          />
          <circle
            mask="url(#tux-loading-green-hole-0)"
            r={110}
            cx="352.5"
            cy={138}
            strokeWidth={0}
            className="tux-loading__red_animation"
            fill="rgb(255, 44, 85)"
          />
        </svg>
      </div>
    </div>
  );
}
