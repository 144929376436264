import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  uniqueNamesGenerator,
  adjectives,
  animals,
} from "unique-names-generator";

const randomMessages = [
  // Tin nhắn ngắn, vui vẻ
  { id: 1, text: "Wow! Nạp xong nhiệm vụ, nhận ngay 5000 xu rồi!" },
  { id: 2, text: "Mới nạp 1450 xu, nhận thêm 5000 xu luôn! Vui ghê!" },
  { id: 3, text: "Nạp thử 100k được 1450 xu + 1450 xu khuyến mãi, ngon!" },
  { id: 4, text: "Đã nhận 15.000 xu từ nhiệm vụ, mọi người yên tâm nhé!" },
  { id: 5, text: "Xong nhiệm vụ rồi, nhận ngay xu vào TikTok, không lo nha!" },
  { id: 6, text: "Nạp 15k xu nhận 50k xu, siêu lời luôn!" },
  { id: 7, text: "Vừa nhận 7.600 xu từ nạp 300k, thêm 7.600 xu free!" },
  { id: 8, text: "Đã làm nhiệm vụ, nhận 20k xu vào TikTok rồi!" },

  // Tin nhắn siêu ngắn
  { id: 9, text: "Xong nhiệm vụ, nhận 5000 xu ngon quá!" },
  { id: 10, text: "Nhận xu vào TikTok rồi nha mọi người!" },
  { id: 11, text: "5000 xu từ nhiệm vụ, sướng ghê!" },
  { id: 12, text: "Nạp 300k, nhận 15.200 xu, lời quá!" },
  { id: 13, text: "Xong! TikTok thưởng 15.000 xu rồi!" },

  // Tin nhắn dài, có cảm xúc
  {
    id: 14,
    text: "Mình vừa nạp 500.000 VNĐ, nhận ngay 12.800 xu + 12.800 xu miễn phí, siêu lời luôn! Mọi người nhanh tay nhé!",
  },
  {
    id: 15,
    text: "Hoàn thành nhiệm vụ nạp 1450 xu và nhận thêm 5000 xu vào tài khoản TikTok rồi. An tâm mà làm nhé, xu về liền!",
  },
  {
    id: 16,
    text: "Vừa nạp 1 triệu VNĐ, nhận tổng cộng 50.400 xu. Bảng giá này quá hời, mọi người đừng bỏ lỡ nha!",
  },
  {
    id: 17,
    text: "Mới nạp 5000 xu, nhận thêm tận 15.000 xu! TikTok làm nhiệm vụ dễ mà nhận xu ngon ghê!",
  },
  {
    id: 18,
    text: "Đã làm xong nhiệm vụ nạp 15.000 xu, nhận thêm 50.000 xu vào tài khoản. TikTok không làm mình thất vọng tí nào!",
  },
  {
    id: 19,
    text: "Hoàn thành nhiệm vụ nạp xu rồi, nhận ngay 20.000 xu vào TikTok. Mọi người cứ làm đi, an tâm nha!",
  },
  {
    id: 20,
    text: "Nạp 100k nhận 1.450 xu + 1.450 xu khuyến mãi! Nhiệm vụ đơn giản mà lời quá!",
  },

  // Tin nhắn kết hợp bảng giá
  {
    id: 21,
    text: "50.000 VNĐ nạp được 350 xu. Mình thử rồi, nhận xu nhanh lắm!",
  },
  {
    id: 22,
    text: "Nạp 200.000 VNĐ nhận 3.700 xu + 3.700 xu khuyến mãi, lời quá trời!",
  },
  {
    id: 23,
    text: "Nạp 1 triệu VNĐ nhận 25.200 xu + 25.200 xu khuyến mãi. Ai nạp thử chưa?",
  },
  {
    id: 24,
    text: "Vừa nạp 300.000 VNĐ, nhận ngay 7.600 xu + 7.600 xu free. Thích ghê!",
  },
  {
    id: 25,
    text: "Nhiệm vụ dễ quá, nạp 1450 xu mà nhận thêm 5000 xu, ai cũng làm được!",
  },

  // Tin nhắn siêu ngắn, vui vẻ
  { id: 26, text: "Xu về TikTok rồi, mọi người nạp đi!" },
  { id: 27, text: "Nạp 5000 xu, nhận thêm 15.000 xu, lời ghê!" },
  { id: 28, text: "Hoàn thành nhiệm vụ, nhận thêm 5000 xu!" },
  { id: 29, text: "Nạp 1450 xu, nhận thêm siêu nhanh luôn!" },
  { id: 30, text: "Xong nhiệm vụ, nhận thêm 20k xu rồi!" },

  // Tin nhắn dài, có cảm xúc và lời khuyên
  {
    id: 31,
    text: "Mình vừa làm xong nhiệm vụ nạp 1450 xu và nhận thêm hẳn 5000 xu vào TikTok. Nhiệm vụ dễ lắm mọi người, đừng lo!",
  },
  {
    id: 32,
    text: "Mới nạp 500.000 VNĐ, nhận tổng cộng 25.600 xu (bao gồm khuyến mãi). Nạp xong xu về liền, làm nhiệm vụ siêu nhanh!",
  },
  {
    id: 33,
    text: "Nạp 300.000 VNĐ được 7.600 xu, thêm 7.600 xu khuyến mãi nữa. TikTok quá xịn, nhận xu nhanh lắm luôn!",
  },
  {
    id: 34,
    text: "Hoàn thành nhiệm vụ nhận 15.000 xu từ 5000 xu nạp. Xu về liền, mọi người cứ làm nhiệm vụ đi nhé!",
  },
  {
    id: 35,
    text: "TikTok nhiệm vụ dễ quá, mình vừa làm xong, nhận ngay 50.000 xu từ 15.000 xu nạp. Xu về liền tay luôn!",
  },

  // Tin nhắn siêu ngắn, ngẫu nhiên
  { id: 36, text: "Nạp xong nhận ngay 5000 xu, vui quá!" },
  { id: 37, text: "Xu vào TikTok rồi nha, yên tâm đi mọi người!" },
  { id: 38, text: "Nhiệm vụ xong, nhận thêm 15k xu!" },
  { id: 39, text: "Nạp thử 100k, nhận 2900 xu, siêu hời!" },
  { id: 40, text: "Wow! Nạp 300k nhận 15k xu free nữa!" },

  // Tin nhắn kết hợp bảng giá và hướng dẫn
  {
    id: 41,
    text: "Nạp 1450 xu, nhận 5000 xu. Mọi người cứ làm nhiệm vụ, đơn giản lắm!",
  },
  {
    id: 42,
    text: "Với 300.000 VNĐ, mình nhận được 15.200 xu (bao gồm khuyến mãi). Xu về nhanh lắm!",
  },
  {
    id: 43,
    text: "Nạp 5000 xu, nhận thêm 15.000 xu. Ai làm nhiệm vụ chưa, dễ lắm luôn!",
  },
  {
    id: 44,
    text: "Mình vừa làm nhiệm vụ nạp 15.000 xu, nhận 50.000 xu ngay vào tài khoản. Ai cũng làm được!",
  },
  { id: 45, text: "Nạp 1 triệu VNĐ nhận 50.400 xu, lời quá trời lời luôn!" },

  // Tin nhắn siêu ngắn, cảm xúc mạnh
  { id: 46, text: "Xu về rồi!!!" },
  { id: 47, text: "5000 xu free!!!" },
  { id: 48, text: "Nhiệm vụ xong, nhận liền tay!" },
  { id: 49, text: "Nạp thử đi, xu về nhanh lắm!" },
  { id: 50, text: "Xu TikTok nhận dễ lắm luôn!" },
];

const ChatBox = () => {
  const user = useSelector((state) => state.auth.user);

  const [messages, setMessages] = useState([
    {
      id: 1,
      sender: "Admin Tiktok",
      text: user.idTiktok
        ? `@${user.idTiktok} ơi, thời gian có hạn hãy làm nhiệm vụ để nhận xu nhé!`
        : "Bạn ơi đăng nhập ngay để làm nhiệm vụ nhận xu từ tiktok nhé",
      timestamp: Date.now(),
    },
  ]); // Khởi tạo với tin nhắn mặc định
  const [remainingMessages, setRemainingMessages] = useState([
    ...randomMessages,
  ]); // Sao chép danh sách gốc
  const maxMessages = 50;

  const messagesContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [input, setInput] = useState("");
  const [isAtBottom, setIsAtBottom] = useState(true);

  // Hàm định dạng thời gian
  const formatTime = (timestamp) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - timestamp) / 1000);

    if (diffInSeconds < 10) return "vừa gửi";
    if (diffInSeconds < 60) return `${diffInSeconds} giây trước`;
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} phút trước`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} giờ trước`;
    return `${Math.floor(diffInSeconds / 86400)} ngày trước`;
  };

  // Hàm tạo tên người gửi ngẫu nhiên (3 ký tự)
  const generateRandomSender = () => {
    const randomName = uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
      length: 2,
      separator: " ",
    });
    return randomName.slice(0, 3) + "******"; // Chỉ lấy 3 ký tự đầu + ẩn phần sau
  };

  // Hàm random tin nhắn
  const getRandomMessage = () => {
    if (remainingMessages.length === 0) {
      // Nếu hết tin nhắn, reset danh sách tin nhắn
      setRemainingMessages([...randomMessages]);
      return null;
    }

    // Chọn tin nhắn ngẫu nhiên từ danh sách còn lại
    const randomIndex = Math.floor(Math.random() * remainingMessages.length);
    const randomMessage = remainingMessages[randomIndex];

    // Xoá tin nhắn đã chọn khỏi danh sách
    const updatedRemainingMessages = remainingMessages.filter(
      (msg, index) => index !== randomIndex
    );
    setRemainingMessages(updatedRemainingMessages);

    return {
      ...randomMessage,
      sender: generateRandomSender(), // Tạo tên người gửi ngẫu nhiên
      timestamp: Date.now(),
    };
  };

  // Thêm tin nhắn ngẫu nhiên vào danh sách
  useEffect(() => {
    let interval;

    // Hàm để thiết lập random tin nhắn
    const setRandomInterval = () => {
      const randomDelay = Math.floor(Math.random() * (4000 - 1000 + 1)) + 1000; // Random từ 1000ms đến 4000ms (1-4 giây)
      interval = setTimeout(() => {
        const randomMessage = getRandomMessage();
        if (randomMessage) {
          setMessages((prev) => {
            const updatedMessages = [...prev, randomMessage];
            return updatedMessages.slice(-maxMessages); // Giới hạn số lượng tin nhắn
          });
        }
        setRandomInterval(); // Gọi lại để thiết lập khoảng thời gian mới
      }, randomDelay);
    };

    setRandomInterval(); // Khởi tạo interval lần đầu

    return () => clearTimeout(interval); // Dọn dẹp khi component unmount
  }, [getRandomMessage, maxMessages]);
  
  // Tự động cuộn khi có tin nhắn mới
  useEffect(() => {
    if (isAtBottom) {
      const container = messagesContainerRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }
  }, [messages, isAtBottom]);

  // Xử lý sự kiện cuộn
  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (container) {
      const isUserAtBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 10;
      setIsAtBottom(isUserAtBottom);
    }
  };

  // Xử lý khi người dùng gửi tin nhắn
  const handleSend = (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const newMessage = {
      id: Date.now(),
      sender: "Bạn",
      text: input,
      timestamp: Date.now(),
    };
    setMessages((prev) => {
      const updatedMessages = [...prev, newMessage];
      return updatedMessages.slice(-maxMessages);
    });
    setInput("");
    inputRef.current.focus();
  };

  return (
    <div className="flex flex-col w-full bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Header */}
      <div className="bg-gradient-to-r from-[rgb(229,252,255)] to-[rgb(177,232,255)] text-color-TextPrimary px-4 py-2 font-bold text-lg flex items-center gap-2">
      <svg class="css-1g0p6jv-StyledInboxIcon e18kkhh41" width="32" data-e2e="true" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.0362 21.3333H18.5243L15.9983 24.4208L13.4721 21.3333H7.96047L7.99557 8H24.0009L24.0362 21.3333ZM24.3705 23.3333H19.4721L17.2883 26.0026C16.6215 26.8176 15.3753 26.8176 14.7084 26.0026L12.5243 23.3333H7.62626C6.70407 23.3333 5.95717 22.5845 5.9596 21.6623L5.99646 7.66228C5.99887 6.74352 6.74435 6 7.66312 6H24.3333C25.2521 6 25.9975 6.7435 26 7.66224L26.0371 21.6622C26.0396 22.5844 25.2927 23.3333 24.3705 23.3333ZM12.6647 14C12.2965 14 11.998 14.2985 11.998 14.6667V15.3333C11.998 15.7015 12.2965 16 12.6647 16H19.3313C19.6995 16 19.998 15.7015 19.998 15.3333V14.6667C19.998 14.2985 19.6995 14 19.3313 14H12.6647Z"></path></svg> Tin nhắn từ người tham gia
      </div>

      {/* Messages Container */}
      <div
        ref={messagesContainerRef}
        onScroll={handleScroll}
        className="overflow-y-auto p-4 space-y-3 h-64 sm:h-[400px]"
      >
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${
              message.sender === "Bạn" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`${
                message.sender === "Bạn"
                  ? "bg-gradient-to-r from-[rgb(229,252,255)] to-[rgb(177,232,255)]"
                  : "bg-gray-200 text-gray-800"
              } rounded-lg px-4 py-2 text-sm max-w-xs`}
            >
              <p className="font-semibold">{message.sender}</p>
              <p>{message.text}</p>
              <p className="text-xs text-gray-500 mt-1">
                {formatTime(message.timestamp)}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Input Form */}
      <form
        onSubmit={handleSend}
        className="flex items-center border-t border-gray-200 p-2 bg-white"
      >
        <input
          type="text"
          ref={inputRef}
          className="flex-1 border border-gray-300 rounded-lg px-4 py-2 mr-2 focus:outline-none focus:ring focus:ring-blue-200"
          placeholder="Nhập tin nhắn..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button
          type="submit"
          className="bg-gradient-to-r from-[rgb(229,252,255)] to-[rgb(177,232,255)] font-bold px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Gửi
        </button>
      </form>
    </div>
  );
};

export default ChatBox;
