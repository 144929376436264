import React, { useState, useEffect } from "react";

const AddToHomeScreen = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Lắng nghe sự kiện `beforeinstallprompt`
    const handleBeforeInstallPrompt = (e) => {
      // Ngăn không cho trình duyệt hiển thị lời nhắc mặc định
      e.preventDefault();
      // Lưu sự kiện vào state
      setDeferredPrompt(e);
      // Hiển thị nút "Add"
      setShowButton(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Cleanup sự kiện khi component bị unmount
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      // Hiển thị lời nhắc "Add to Home Screen"
      deferredPrompt.prompt();

      // Chờ người dùng trả lời
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("Người dùng đã thêm ứng dụng vào màn hình chính.");
        } else {
          console.log(
            "Người dùng đã từ chối thêm ứng dụng vào màn hình chính."
          );
        }
        // Đặt lại `deferredPrompt`
        setDeferredPrompt(null);
      });
    }
  };

  if (!showButton) {
    return null; // Không hiển thị nút nếu không có sự kiện
  }

  return (
    <div className="flex items-center gap-[12px]">
      <img
        src="https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/unified-wallet/resource/image/home_screen_thumbnail.dee906dc.png"
        width={56}
        height={56}
        alt="home_screen_thumbnail"
      />
      <div className="flex-grow font-medium banner-r12RJu">
        <p>Add this page to home screen for one-tap access</p>
        <button
          className="tux-button box-border flex select-none appearance-none outline-none cursor-pointer border-solid items-center justify-center px-0 relative w-full radius-control-tiny flex-shrink-0 tux-button-secondary text-color-TextPrimary background-color-BGInput border-0 P3-Semibold"
          type="button"
          style={{ height: 24, width: 64 }}
          onClick={handleAddToHomeScreen}
        >
          <div className="flex items-center justify-center w-full px-[6px]">
            <span
              className="truncate"
              data-test-tag="tux-button-content"
              style={{ lineHeight: "24px" }}
            >
              Add
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AddToHomeScreen;
