import React, { useEffect, useRef, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { isMobile } from "react-device-detect";

import { useDispatch, useSelector } from "react-redux";
import Loading from "./components/Loading";
import MobileRoutes from "./routes/MobileRoutes";
import PCRoutes from "./routes/PCRoutes";
import {
  getBalanceSuccess,
  getGiftSuccess,
  getPackagesSuccess,
  getTransactions,
  getTransactionSuccess,
  loginSuccess,
  logout,
} from "./store/actions/authActions";
import axiosInstance from "./utils/axiosInstance";
import SagesslashPage from "./pages/SagesslashPage";
import { io } from "socket.io-client";
import toast, { Toaster } from "react-hot-toast";

const App = () => {
  const [loading, setLoading] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const [isConnected, setIsConnected] = useState(false);

  const wsRef = useRef(null); // Lưu trữ WebSocket
  // useEffect(() => {
    // const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    // const host = window.location.host;

    // let ws;
    // let reconnectInterval;
    // let keepAliveInterval;

    // const connectWebSocket = () => {
    //   ws = new WebSocket(`${protocol}${host.includes("localhost") ? 'localhost:8000': host}/ws/`);
    //   wsRef.current = ws;

    //   ws.onopen = () => {
    //     console.log("WebSocket connection opened");
    //     setIsConnected(true);

    //     // Gửi "ping" định kỳ
    //     keepAliveInterval = setInterval(() => {
    //       if (ws.readyState === WebSocket.OPEN) {
    //         ws.send(JSON.stringify({ type: "ping" }));
    //       }
    //     }, 30000); // 30 giây

    //     clearInterval(reconnectInterval); // Ngừng thử kết nối lại
    //   };

    //   ws.onmessage = (event) => {
    //     const data = JSON.parse(event.data);
    //     if (data.type === "pong") {
    //       console.log("Received pong response from server");
    //     }
    //     if (data.type === "notification") {
    //       toast(data.message, {
    //         position: "top-center",
    //         duration: 3000, // Tự động tắt sau 2 giây
    //         style: {
    //           border: "1px solid #713200",
    //           padding: "5px 12px",
    //           color: "#713200",
    //           borderRadius: "8px",
    //         },
    //       });
    //     }
    //   };

    //   ws.onclose = () => {
    //     console.log("WebSocket connection closed");
    //     setIsConnected(false);
    //     clearInterval(keepAliveInterval); // Ngừng gửi "ping"

    //     // Tự động kết nối lại sau 5 giây
    //     reconnectInterval = setInterval(() => {
    //       console.log("Attempting to reconnect WebSocket...");
    //       connectWebSocket();
    //     }, 5000);
    //   };

    //   ws.onerror = (error) => {
    //     console.error("WebSocket error:", error);
    //   };
    // };

    // connectWebSocket();

    // return () => {
    //   ws.close();
    //   clearInterval(reconnectInterval);
    //   clearInterval(keepAliveInterval);
    // };
  // }, []);

  // useEffect(() => {
  //   if (!isConnected || !wsRef.current) {
  //     return;
  //   }

  //   // Gửi JWT token nếu có
  //   if (isLoggedIn) {
  //     const token = localStorage.getItem("jwtToken");
  //     wsRef.current.send(JSON.stringify({ type: "authenticate", token }));
  //     console.log("JWT token sent to server:", token);
  //   } else {
  //     wsRef.current.send(JSON.stringify({ type: "guest" }));
  //     console.warn("No JWT token found in localStorage");
  //   }
  // }, [isLoggedIn, isConnected]);

  useEffect(() => {
    if (isLoggedIn) {
      const getBalance = async () => {
        try {
          const response = await axiosInstance("/get-balance");
          dispatch(getBalanceSuccess(response.data.data));
        } catch (error) {
          console.error("Failed to fetch balance:", error);
        }
      };
      const getGift = async () => {
        try {
          const response = await axiosInstance("/get-gift");
          dispatch(getGiftSuccess(response.data.data));
        } catch (error) {
          console.error("Failed to fetch balance:", error);
        }
      };
      const getTransactionsList = async () => {
        try {
          dispatch(getTransactions());
          const response = await axiosInstance("/get-transactions");
          dispatch(getTransactionSuccess(response.data.data));
        } catch (error) {
          console.error("Failed to fetch balance:", error);
        }
      };

      Promise.all([getBalance(), getGift(), getTransactionsList()]);
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    const getPackages = async () => {
      try {
        const response = await axiosInstance("/get-packages");
        dispatch(getPackagesSuccess(response.data.data));
      } catch (error) {
        console.error("Failed to fetch packages:", error);
      }
    };
    getPackages();

    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (token) {
      dispatch(loginSuccess(userData));
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/sagesslash" element={<SagesslashPage />} />
        </Routes>
        <>{isMobile ? <MobileRoutes /> : <PCRoutes />}</>
      </Router>
      <Toaster />
    </>
  );
};
export default App;
