import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberToCurrency } from "../../helpers/helpers";
import { logout } from "../../store/actions/authActions";
import CashierModal from "../CashierModal";
import HeaderMobile from "../HeaderMobile";
import LoginModal from "../LoginModal";

export default function LayoutMobile({
  children,
  setShowLogin,
  showLogin,
  setPackageSelected,
  packageSelected,
  setShowLogout,
  showLogout,
}) {
  const [showRecharge, setShowRecharge] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const handleShowRecharge = () => {
    if (!isLoggedIn) {
      setShowLogin(true);
      return;
    }
    setShowRecharge(true);
  };

  const handleCloseRecharge = () => {
    setPackageSelected({});
    setShowRecharge(false);
  };

  const handleLogout = () => {
    // Logout logic here
    dispatch(logout());
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userData");
    setShowLogout(false);
    setPackageSelected({});
  };

  return (
    <>
      <div
        className="flex flex-col h-screen justify-between background-color-BGBase"
        style={{ maxHeight: "-webkit-fill-available", height: "100dvh" }}
      >
        <HeaderMobile />
        <main className="pb-[16px] flex-1" style={{ overflowY: "auto" }}>
          <div className="pt-[4px]">{children}</div>
        </main>
        <footer>
          <div
            className="px-[16px] py-[12px] background-color-BGPrimary"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 12px 0px",
              transform: "translateZ(0px)",
            }}
          >
            <div className="pb-[10px] flex justify-center">
              <img
                className="h-[16px] w-[26px] me-[4px]"
                src="https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/momo_4256e5.png"
                data-e2e="wallet-payment-icon-MOMO"
                alt="MOMO"
                style={{
                  display: "inline-block",
                  border: "0.5px solid rgb(225, 228, 236)",
                  borderRadius: 4,
                }}
              />
              <img
                className="h-[16px] w-[26px] me-[4px]"
                src="https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/zalopay_8e254f.png"
                alt="ZALOPAY"
                style={{
                  display: "inline-block",
                  border: "0.5px solid rgb(225, 228, 236)",
                  borderRadius: 4,
                }}
              />
              <img
                className="h-[16px] me-[4px]"
                src="/images/thecao/viettel.png"
                alt="VIETTEL"
                style={{
                  display: "inline-block",
                  border: "0.5px solid rgb(225, 228, 236)",
                  borderRadius: 4,
                }}
              />
              <img
                className="h-[16px] me-[4px]"
                src="/images/thecao/mobifone.png"
                alt="MOBIFONE"
                style={{
                  display: "inline-block",
                  border: "0.5px solid rgb(225, 228, 236)",
                  borderRadius: 4,
                }}
              />
              <img
                className="h-[16px] me-[4px]"
                src="/images/thecao/vinaphone.png"
                alt="VINAPHONE"
                style={{
                  display: "inline-block",
                  border: "0.5px solid rgb(225, 228, 236)",
                  borderRadius: 4,
                }}
              />
              <img
                className="h-[16px] me-[4px]"
                src="/images/thecao/zing.png"
                alt="ZING"
                style={{
                  display: "inline-block",
                  border: "0.5px solid rgb(225, 228, 236)",
                  borderRadius: 4,
                }}
              />
              <img
                className="h-[16px] me-[4px]"
                src="/images/thecao/garena.png"
                alt="GARENA"
                style={{
                  display: "inline-block",
                  border: "0.5px solid rgb(225, 228, 236)",
                  borderRadius: 4,
                }}
              />
            </div>

            <div data-e2e="wallet-buy-now-button">
              <button
                className={`tux-button box-border flex select-none appearance-none outline-none cursor-pointer border-solid items-center justify-center px-0 relative w-full radius-control-large ${
                  packageSelected.amount
                    ? "text-color-ConstTextInverse background-color-Primary"
                    : "text-color-TextQuaternary background-color-BGInput2"
                }  border-0 H4-Semibold`}
                type="button"
                style={{ height: 44 }}
                disabled={!packageSelected.amount}
                onClick={handleShowRecharge}
              >
                <div className="flex items-center justify-center w-full px-[6px]">
                  <span
                    className="me-[4px]"
                    style={{ fontSize: 20, lineHeight: 0 }}
                  >
                    <svg
                      fill="currentColor"
                      viewBox="0 0 48 48"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <path d="M28.22 13.7a4.83 4.83 0 0 0 4.86 4.8l-.04 3.45c-1.77 0-3.4-.53-4.75-1.43l-.09 6.99a6.64 6.64 0 0 1-6.69 6.3 6.44 6.44 0 0 1-6.53-6.45 6.63 6.63 0 0 1 7.61-6.4l-.04 3.7a2.97 2.97 0 0 0-3.88 2.7 2.85 2.85 0 0 0 2.89 2.85 2.93 2.93 0 0 0 2.96-2.85l.16-13.66h3.54Z" />
                      <path d="M5 23.15V10.5c0-1.2 1.06-2.17 2.26-2.28 3.05-.25 8.87-1.52 15.34-6.99.8-.67 2-.67 2.8 0 6.46 5.46 12.28 6.74 15.34 7 1.2.1 2.26 1.07 2.26 2.27v12.65c0 14.13-14.77 21.8-18.27 23.43-.47.22-1 .22-1.47 0C19.76 44.96 5 37.28 5 23.15Zm4-11.1v11.1c0 5.57 2.88 10.04 6.56 13.5 3.1 2.91 6.5 4.86 8.44 5.85a37.07 37.07 0 0 0 8.43-5.85C36.11 33.2 39 28.72 39 23.15V12.04c-3.65-.53-9.1-2.16-15-6.8-5.91 4.64-11.35 6.27-15 6.8Z" />
                    </svg>
                  </span>
                  <span
                    className="truncate"
                    data-test-tag="tux-button-content"
                    style={{ lineHeight: "44px" }}
                  >
                    {packageSelected.amount
                      ? `Thanh toán ₫${formatNumberToCurrency(
                          packageSelected.amount
                        )}`
                      : "Vui lòng chọn gói nạp"}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </footer>
        <div className="box-border text-color-TextPrimary">
          <div
            className={`tt-sheet__mask fixed background-color-SDSecondary z-[2000] ${
              showLogout ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
            onClick={() => setShowLogout(false)}
          />
          <div
            className={`tt-sheet__container radius-containerslevel1-large-t pb-safe fixed z-[2000] flex flex-col high visible`}
            style={{
              minHeight: 100,
              maxHeight: "80%",
              backgroundColor: "rgb(255, 255, 255)",
              transform: `translateY(${showLogout ? "0" : "100%"})`,
            }}
          >
            <div className="tt-sheet__header flex-shrink-0">
              <div data-theme="light" dir="ltr">
                <div data-theme="light" dir="ltr">
                  <div data-test-tag="tux-nav-bar" className="TUXNavBar">
                    <div className="box-border top-0 left-0 right-0">
                      <header
                        className="flex items-center px-[0px] overflow-hidden relative"
                        role="navigation"
                        style={{ height: 52 }}
                      >
                        <div
                          role="none"
                          className="absolute w-full h-full top-0 left-0 z-[-1]"
                          style={{ backgroundColor: "rgb(255, 255, 255)" }}
                        />
                        <div className="inline-flex items-center justify-start flex-1 h-full" />
                        <div
                          data-test-tag="tux-nav-bar-content"
                          className="truncate flex-3 px-[4px] text-center H3-Bold"
                          style={{
                            color: "rgb(0, 0, 0)",
                            lineHeight: "52px",
                          }}
                        >
                          Account
                        </div>
                        <div className="flex-1 inline-flex items-center justify-end h-full">
                          <button
                            data-test-tag="tux-nav-bar-button-0"
                            className="tux-nav-bar-icon-action h-[40px] w-[40px] p-0 text-color-TextPrimary flex items-center justify-center"
                          >
                            <span
                              data-e2e="account-sheet-close-icon"
                              onClick={() => setShowLogout(false)}
                            >
                              <svg
                                fill="currentColor"
                                viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                              >
                                <path d="M10.19 36.19a1 1 0 0 1 0-1.42L20.96 24 10.19 13.23a1 1 0 0 1 0-1.42l1.62-1.62a1 1 0 0 1 1.42 0L24 20.96l10.77-10.77a1 1 0 0 1 1.42 0l.09.09.01.01 1.42 1.42.01.01.1.1a1 1 0 0 1 0 1.4L27.03 24l10.77 10.77a1 1 0 0 1 0 1.42l-.1.09v.01l-1.42 1.42-.1.1a1 1 0 0 1-1.42 0L24 27.04 13.23 37.81a1 1 0 0 1-1.42 0L10.2 36.2Z" />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div className="absolute bottom-0 left-0 right-0">
                          <div
                            role="separator"
                            className="flex flex-shrink-0 box-border tux-separator--horizontal tux-separator--medium"
                            style={{
                              backgroundColor: "rgba(22, 24, 35, 0.2)",
                              transformOrigin: "center bottom",
                            }}
                          />
                        </div>
                      </header>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tt-sheet__body flex-1">
              <div className="mb-[12px]">
                <div className="flex p-[16px] pb-[8px] mx-[8px] justify-between">
                  <div className="flex">
                    <div
                      data-test-tag="tux-avatar"
                      className="rounded-full"
                      style={{
                        backgroundImage: `url("${user.avatar}")`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "50% 50%",
                        width: 56,
                        height: 56,
                      }}
                    >
                      <div
                        className="rounded-full border border-solid border-color-LineSecondary box-border"
                        style={{ width: 56, height: 56 }}
                      />
                    </div>
                    <div className="ms-[12px] h-[56px] flex flex-col justify-center">
                      <div className="Headline-Regular me-[4px] text-color-TextPrimary">
                        {user.idTiktok}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <svg
                      fill="currentColor"
                      fontSize="24px"
                      color="rgba(254, 44, 85, 1)"
                      viewBox="0 0 48 48"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <path d="m19.71 36.03 19.73-30.5a1 1 0 0 1 1.39-.3l2.35 1.53c.46.3.6.92.3 1.38L22.01 41.3a2.4 2.4 0 0 1-3.83.28L4.85 26.33a1 1 0 0 1 .1-1.4l2.1-1.85a1 1 0 0 1 1.42.1L19.7 36.02Z" />
                    </svg>
                  </div>
                </div>
                <div
                  className="my-[12px] mx-[16px]"
                  data-e2e="account-sheet-logout-button-container"
                >
                  <button
                    className="tux-button box-border flex select-none appearance-none outline-none cursor-pointer border-solid items-center justify-center px-0 relative w-full radius-control-large text-color-ConstTextInverse background-color-Primary border-0 H4-Semibold"
                    type="button"
                    style={{ height: 44 }}
                    onClick={handleLogout}
                  >
                    <div className="flex items-center justify-center w-full px-[6px]">
                      <span
                        className="truncate"
                        data-test-tag="tux-button-content"
                        style={{ lineHeight: "44px" }}
                      >
                        Log out
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LoginModal setShowLogin={setShowLogin} showLogin={showLogin} />
        {showRecharge && (
          <CashierModal
            closeRecharge={handleCloseRecharge}
            showRecharge={showRecharge}
            data={packageSelected}
          />
        )}
      </div>
    </>
  );
}
