import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const CountdownTimer = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Trạng thái đăng nhập
  const user = useSelector((state) => state.auth.user); // Thông tin người dùng

  const [timeLeft, setTimeLeft] = useState(null); // Thời gian đếm ngược
  const [initialized, setInitialized] = useState(false); // Kiểm tra khởi tạo
  const [warning, setWarning] = useState(false); // Trạng thái cảnh báo
  // Thêm một trạng thái để kiểm soát việc gọi alert
  const [hasExpiredAlerted, setHasExpiredAlerted] = useState(false);

  // Helper function: Tính thời gian còn lại đến 12h trưa hoặc 12h đêm
  const calculateTimeToTarget = () => {
    const now = new Date();
    const target = new Date();

    if (now.getHours() < 12) {
      target.setHours(12, 0, 0, 0); // Đặt mục tiêu là 12h trưa
    } else {
      target.setHours(24, 0, 0, 0); // Đặt mục tiêu là 12h đêm
    }

    return Math.floor((target - now) / 1000); // Thời gian còn lại tính bằng giây
  };

  // Khởi tạo thời gian đếm ngược
  useEffect(() => {
    const storedUsers = JSON.parse(localStorage.getItem("usersData")) || {}; // Lấy dữ liệu của tất cả người dùng
    const storedExpiry = storedUsers[user?.idTiktok]?.expiry || null; // Lấy thời gian hết hạn của tài khoản hiện tại
    const hasExpired = storedUsers[user?.idTiktok]?.expired || false; // Trạng thái hết hạn của tài khoản hiện tại

    if (isLoggedIn && user?.idTiktok) {
      if (hasExpired) {
        // Nếu tài khoản đã hết hạn, hiển thị thông báo
        showAlertTimeExpired(storedUsers);
        setTimeLeft(0); // Đặt thời gian còn lại là 0
      } else if (storedExpiry) {
        // Nếu đã có thời gian hết hạn cho tài khoản này
        const remainingTime = Math.floor((storedExpiry - Date.now()) / 1000);
        if (remainingTime > 0) {
          setTimeLeft(remainingTime); // Sử dụng thời gian còn lại
        } else {
          // Nếu thời gian đã hết, đánh dấu tài khoản là hết hạn
          storedUsers[user.idTiktok] = { expiry: 0, expired: true };
          showAlertTimeExpired(storedUsers);
          setTimeLeft(0); // Đặt thời gian còn lại là 0
        }
      } else {
        // Nếu chưa có thời gian hết hạn cho tài khoản này
        const newExpiry = Date.now() + 60 * 1000 * 60;
        storedUsers[user.idTiktok] = { expiry: newExpiry, expired: false }; // Lưu thời gian hết hạn
        localStorage.setItem("usersData", JSON.stringify(storedUsers));
        setTimeLeft(60 * 60); // 1 phút tính bằng giây
      }
    } else {
      // Nếu không đăng nhập
      const remainingTime = calculateTimeToTarget();
      setTimeLeft(remainingTime); // Đặt countdown đến 12h trưa hoặc 12h đêm
    }

    setInitialized(true); // Đánh dấu đã khởi tạo xong
  }, [isLoggedIn, user]);

  // Countdown logic
  useEffect(() => {
    if (!initialized || timeLeft === null) return;

    if (timeLeft <= 0) {
      if (!hasExpiredAlerted) {
        if (isLoggedIn) {
          // Khi thời gian hết, đánh dấu tài khoản là hết hạn
          const storedUsers =
            JSON.parse(localStorage.getItem("usersData")) || {};
          storedUsers[user.idTiktok] = { expiry: 0, expired: true };
          showAlertTimeExpired(storedUsers);
          setHasExpiredAlerted(true); // Đảm bảo alert chỉ hiển thị một lần
        } else {
          // Reset countdown cho khách
          const remainingTime = calculateTimeToTarget();
          setTimeLeft(remainingTime); // Đếm ngược đến 12h trưa hoặc 12h đêm
          setHasExpiredAlerted(false); // Reset trạng thái alert cho khách
        }
      }
      return;
    }

    // Cập nhật trạng thái cảnh báo nếu còn dưới 10 phút
    if (timeLeft <= 30 * 60 && !warning) {
      setWarning(true);
    }

    // Cập nhật countdown mỗi giây
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval khi component unmount
  }, [timeLeft, isLoggedIn, initialized, warning, hasExpiredAlerted]);

  const showAlertTimeExpired = (storedUsers) => {
    localStorage.setItem("usersData", JSON.stringify(storedUsers));
    Swal.fire({
      title: "Thời gian đã hết!",
      text: "Bạn có muốn gia hạn thêm 30 phút để làm nhiệm vụ nhận phần thưởng đặc biệt từ TikTok không? Đây là cơ hội cuối cùng, đừng bỏ lỡ!",
      icon: "warning",
      allowOutsideClick: false, // Ngăn chặn nhấn ra ngoài
      allowEscapeKey: false, // Ngăn chặn nhấn phím ESC
      confirmButtonText: "Tiếp tục làm nhiệm vụ",
    }).then((result) => {
      if (result.isConfirmed) {
        const newExpiry = Date.now() + 30 * 60 * 1000; // Thêm 30 phút
        storedUsers[user.idTiktok] = { expiry: newExpiry, expired: false };
        localStorage.setItem("usersData", JSON.stringify(storedUsers));
        setTimeLeft(30 * 60); // Gia hạn 30 phút
        setWarning(false); // Tắt trạng thái cảnh báo
      }
    });
  };

  // Format thời gian thành HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <p>
      Thời gian còn lại:{" "}
      <b
        className={`${
          warning ? "text-red-500 animate-pulse" : "" // Thêm hiệu ứng nháy đỏ
        }`}
      >
        {timeLeft !== null ? formatTime(timeLeft) : "Đang tải..."}
      </b>
    </p>
  );
};

export default CountdownTimer;
