import React, { useState } from "react";
import Header from "../Header";
import LoginModal from "../LoginModal";

export default function LayoutPC({ children, showLogin, setShowLogin }) {
  return (
    <>
      {/* Layout */}
      <Header setShowLogin={setShowLogin} />
      {/* Nội dung trang */}
      <div>
        <div className="main-container">
          <div className="main-content-container-W1JWZd">{children}</div>
        </div>
        <LoginModal setShowLogin={setShowLogin} showLogin={showLogin} />
      </div>
    </>
  );
}
