import React from "react";
import { Link } from "react-router-dom";

export default function Transactions() {
  return (
    <div className="transaction-content-wrapper-uvUPal">
      <div className="w-full h-[28px]">
        <Link to="/coins">
          <div
            className="pointer-cursor inline-block"
            role="img"
            data-e2e="back"
            style={{ verticalAlign: "top" }}
          >
            <svg
              fill="currentColor"
              className="flip-rtl"
              color="inherit"
              fontSize={27}
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              style={{ color: "rgba(32, 32, 32, 1)" }}
            >
              <path d="m19.26 24 13.66-13.67a1 1 0 0 0 0-1.41l-1.84-1.84a1 1 0 0 0-1.41 0L13.46 23.3a1 1 0 0 0 0 1.42l16.2 16.21a1 1 0 0 0 1.42 0l1.84-1.84a1 1 0 0 0 0-1.41L19.26 24Z" />
            </svg>
          </div>
        </Link>
        <h4
          className="inline-block H4-Regular"
          style={{
            lineHeight: "27px",
            verticalAlign: "middle",
            color: "rgba(32, 32, 32, 1)",
            fontSize: 24,
          }}
        >
          Transaction history
        </h4>
      </div>
      <div className="w-full mt-[16px] ml-[20px]">
        <span
          className="transaction-title-desc-a97ZZp"
          style={{ color: "var(--ui-text-3)" }}
        >
          This page only shows the transaction history of your Coins purchased
          through TikTok.com.
        </span>
        <span
          className="transaction-title-desc-a97ZZp cursor-pointer"
          style={{ color: "var(--ui-text-3)" }}
        >
          <a href="/coin/legal/terms-of-purchase-for-coins">
            View Terms of Purchase for Coins
          </a>
        </span>
      </div>
      <div className="transaction-table-content-wrapper-UP33uU">
        <div className="mt-[16px] w-full">
          <div className="relative">
            <div className="sc-imWYAI eYUCce" style={{ width: "unset" }}>
              <table className="w-full" style={{ borderCollapse: "collapse" }}>
                <thead
                  className="sc-jXbUNg fhRPSq"
                  style={{
                    borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                    textAlign: "center",
                  }}
                >
                  <tr className="sc-dhKdcB sc-kpDqfm dumyQE jCVLsR">
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{ color: "rgba(32, 32, 32, 1)" }}
                    >
                      Amount
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{ color: "rgba(32, 32, 32, 1)" }}
                    >
                      Phương thức thanh toán
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{ color: "rgba(32, 32, 32, 1)" }}
                    >
                      Payment
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{ color: "rgba(32, 32, 32, 1)" }}
                    >
                      Order ID
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{ color: "rgba(32, 32, 32, 1)" }}
                    >
                      Created time
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{ color: "rgba(32, 32, 32, 1)" }}
                    >
                      Completed time
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{ color: "rgba(32, 32, 32, 1)" }}
                    >
                      Status
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        color: "rgba(32, 32, 32, 1)",
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      Invoice
                    </td>
                  </tr>
                </thead>
                <tbody
                  className="sc-dAlyuH jGnpzp flex-col overflow-auto"
                  style={{
                    borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                    textAlign: "center",
                  }}
                >
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                  <tr
                    className="sc-dhKdcB dumyQE"
                    style={{
                      borderBottom: "1px solid rgba(22, 24, 35, 0.12)",
                      textAlign: "center",
                    }}
                  >
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td className="sc-jlZhew gsJfOs" style={{ fontSize: 14 }}>
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                    <td
                      className="sc-jlZhew gsJfOs"
                      style={{
                        fontSize: 14,
                        flex: "0 0 300px",
                        overflow: "hidden",
                        width: 300,
                      }}
                    >
                      <div className="sc-cwHptR dWpIjx" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
