import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../store/actions/authActions";
import axios from "axios";
import axiosInstance from "../utils/axiosInstance";

export default function LoginModal({ setShowLogin, showLogin }) {
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (username === "") {
      setError("Vui lòng nhập id tiktok của bạn");
      return;
    }
    try {
      setLoading(true);
      const response = await axiosInstance.post("/login", {
        username,
      });
      const json = response.data;
      if (json.status) {
        const token = json.token;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userData", JSON.stringify(json.data));
        dispatch(loginSuccess(json.data));
        setShowLogin(false);
        setUsername("");
      }
    } catch (error) {
      setError(error.response?.data?.message);
    }
    setLoading(false);
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
    setError(null);
  };

  return (
    <div
      className={`tiktok-behvuc-DivModalContainer e1gjoq3k0 ${
        showLogin ? "flex" : "!hidden"
      }`}
      style={{ zIndex: 3001, position: "fixed" }}
    >
      <div className="tiktok-v2a75e-DivModalMask e1gjoq3k2" />
      <div
        data-focus-guard="true"
        tabIndex={0}
        style={{
          width: 1,
          height: 0,
          padding: 0,
          overflow: "hidden",
          position: "fixed",
          top: 1,
          left: 1,
        }}
      />
      <div data-focus-lock-disabled="false">
        <div className="tiktok-1dw2wty-DivCenterWrapper e1gjoq3k7">
          <div
            data-e2e
            tabIndex={0}
            id="login-modal"
            className="tiktok-188zowr-DivContentContainer e1gjoq3k3"
          >
            <div
              role="dialog"
              aria-modal="true"
              aria-labelledby="login-modal-title"
              data-e2e="login-modal"
              className="tiktok-1e4hvda-DivModalContent eg439om2"
            >
              <div
                id="loginContainer"
                className="tiktok-2z4axd-DivPageWrapper eg439om3"
              >
                <link
                  rel="stylesheet"
                  href="https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok/webapp/main/webapp-wallet-desktop/8c38d0bc3eca55cfff39.css"
                />
                <div className="tiktok-aa97el-DivLoginContainer exd0a430">
                  <h2
                    id="login-modal-title"
                    className="tiktok-1xjm8uw-H2Title e1521l5b1"
                  >
                    Đăng nhập Tiktok
                  </h2>
                  <div className="tiktok-1usm4ad-DivDescription e1521l5b3">
                    Username tiktok
                  </div>
                  <form onSubmit={handleLogin}>
                    <div className="tiktok-q83gm2-DivInputContainer etcs7ny0">
                      <input
                        type="text"
                        placeholder="Nhập username tài khoản tiktok của bạn"
                        className="tiktok-11to27l-InputContainer etcs7ny1"
                        value={username}
                        onChange={handleChangeUsername}
                      />
                      <div className="tiktok-txpjn9-DivIconContainer etcs7ny2" />
                    </div>
                    {error && (
                      <div
                        type="error"
                        aria-live="assertive"
                        className="tiktok-tfg9wk-DivTextContainer e3v3zbj0"
                      >
                        <span role="status">{error}</span>
                      </div>
                    )}
                    <button
                      type="submit"
                      data-e2e="login-button"
                      disabled={!username.match(/^@?[a-zA-Z0-9_.]{5,24}$/)}
                      className="e1w6iovg0 tiktok-11sviba-Button-StyledButton ehk74z00"
                    >
                      {loading ? (
                        <div
                          className="TUXLoadingSpinner-container TUXButton-spinner"
                          style={{ width: 20, height: 20 }}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width={20}
                            height={20}
                            className="TUXLoadingSpinner"
                          >
                            <circle
                              cx={12}
                              cy={12}
                              r="10.5"
                              stroke="currentColor"
                              strokeWidth={3}
                              strokeDasharray="65.97344572538566"
                              strokeDashoffset="13.194689145077128"
                              strokeLinecap="round"
                              fill="none"
                            />
                          </svg>
                        </div>
                      ) : (
                        "Đăng nhập ngay"
                      )}
                    </button>
                  </form>
                  <div className="mt-5">
                    <h3 className="font-bold text-center mb-3">Hướng dẫn lấy Username Tiktok</h3>
                    <div className="border">
                      <img src="/images/huongdan.png" alt="huong-dan" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tiktok-13vgxo2-DivContainer e1b6crsh0 flex flex-col">
                <div data-e2e="bottom-text">Tiktok không yêu cầu mật khẩu</div>
                <div className="tiktok-1pp5uu9-ALink epl6mg0">
                  <span
                    data-e2e="bottom-sign-up"
                    className="tiktok-1r3zw3h-SpanLinkText e1b6crsh1 ml-0"
                  >
                    Vui lòng không cấp mật khẩu cho bất kỳ ai
                  </span>
                </div>
              </div>
            </div>
            <div
              data-e2e="modal-close-inner-button"
              role="button"
              tabIndex={0}
              aria-label="Close"
              className="tiktok-1ecw34m-DivCloseWrapper e1gjoq3k6"
              style={{ transform: "scale(1.7)" }}
              onClick={() => setShowLogin(false)}
            >
              <svg
                className="tiktok-1anes8e-StyledIcon e1gjoq3k5"
                width="1em"
                data-e2e
                height="1em"
                viewBox="0 0 48 48"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.1718 23.9999L10.2931 13.1212C9.90261 12.7307 9.90261 12.0975 10.2931 11.707L11.7074 10.2928C12.0979 9.90228 12.731 9.90228 13.1216 10.2928L24.0002 21.1715L34.8789 10.2928C35.2694 9.90228 35.9026 9.90228 36.2931 10.2928L37.7073 11.707C38.0979 12.0975 38.0979 12.7307 37.7073 13.1212L26.8287 23.9999L37.7073 34.8786C38.0979 35.2691 38.0979 35.9023 37.7073 36.2928L36.2931 37.707C35.9026 38.0975 35.2694 38.0975 34.8789 37.707L24.0002 26.8283L13.1216 37.707C12.731 38.0975 12.0979 38.0975 11.7074 37.707L10.2931 36.2928C9.90261 35.9023 9.90261 35.2691 10.2931 34.8786L21.1718 23.9999Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        data-focus-guard="true"
        tabIndex={0}
        style={{
          width: 1,
          height: 0,
          padding: 0,
          overflow: "hidden",
          position: "fixed",
          top: 1,
          left: 1,
        }}
      />
    </div>
  );
}
