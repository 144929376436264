import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { formatNumberToCurrency } from "../helpers/helpers";
import CashierModal from "../components/CashierModal";
import LoginModal from "../components/LoginModal";
import AddToHomeScreen from "../components/AddToHomeScreen";
import CountdownTimer from "../components/CountdownTimer";
import TransactionHistory from "../components/TransactionHistory";
import ChatBox from "../components/ChatBox";

export default function CoinPageMobile({
  setShowLogin,
  packageSelected,
  setPackageSelected,
  setShowLogout,
}) {
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const packages = useSelector((state) => state.auth.packages);
  const giftUnUsed = useSelector((state) => state.auth.giftUnUsed);
  const gifts = useSelector((state) => state.auth.gifts);
  const transactions = useSelector((state) => state.auth.transactions);

  const [totalPromotionsUsed, setTotalPromotionsUsed] = useState(0);

  useEffect(() => {
    setTotalPromotionsUsed(
      gifts.reduce(
        (acc, item) => acc + (item.statusReceived ? item.promotions : 0),
        0
      )
    );
  }, [gifts]);

  const handleChangePackageSelected = (obj) => {
    setPackageSelected(obj);
  };

  const getContentGift = () => {
    if (isLoggedIn) {
      if (!giftUnUsed) {
        return `Bạn đã hoàn thành nhiệm vụ nhận ${formatNumberToCurrency(
          totalPromotionsUsed
        )} xu`;
      }
      if (giftUnUsed?.coins < user.balance_base) {
        return `Nạp thẻ với mệnh giá bất kỳ để nhận ${formatNumberToCurrency(
          giftUnUsed.promotions
        )} xu`;
      } else {
        return `Hoàn thành nhiệm vụ để nhận ${formatNumberToCurrency(
          giftUnUsed.promotions
        )} xu`;
      }
    } else {
      return "Cơ hội nhận 1,000 xu hôm nay đăng nhập để biết thêm chi tiết";
    }
  };

  return (
    <>
      <div>
        <div
          data-e2e="recharge-info-avatar"
          className="px-[16px] pb-[12px] mx-[8px] background-color-UIPageFlat1 radius-containerslevel0-small"
        >
          <div
            className="flex justify-center items-center text-color-UIShapeSuccess4"
            style={{ position: "relative", height: 17, gap: "1.71px" }}
          >
            <svg
              fill="currentColor"
              className="text-color-UIShapeText2OnSuccess"
              color="inherit"
              fontSize="9.58px"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
            >
              <path d="M22.6 1.24c-6.47 5.47-12.29 6.74-15.34 7C6.06 8.34 5 9.32 5 10.53V23.2c0 14.16 14.76 21.85 18.26 23.47.47.22 1 .22 1.47 0C28.23 45.05 43 37.37 43 23.2V10.52c0-1.2-1.06-2.18-2.26-2.28-3.06-.26-8.88-1.53-15.35-7a2.17 2.17 0 0 0-2.79 0Zm5.62 12.46a4.83 4.83 0 0 0 4.86 4.8l-.04 3.45c-1.77 0-3.4-.53-4.75-1.43l-.09 6.99a6.64 6.64 0 0 1-6.69 6.3 6.44 6.44 0 0 1-6.53-6.45 6.63 6.63 0 0 1 7.61-6.4l-.04 3.7a2.93 2.93 0 0 0-3.88 2.7 2.85 2.85 0 0 0 2.89 2.85 2.93 2.93 0 0 0 2.96-2.85l.16-13.66h3.54Z" />
            </svg>
            <strong
              className="text-center SmallText2-Medium  text-color-UIShapeText2OnSuccess"
              data-e2e="secure-payment-sign"
            >
              Secure payment
            </strong>
            <svg
              fill="currentColor"
              className="flip-rtl text-color-UIShapeText2OnSuccess"
              color="inherit"
              fontSize="9.58px"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
            >
              <path d="M27.76 24 14.59 10.83a1 1 0 0 1 0-1.42L17.4 6.6a1 1 0 0 1 1.42 0l16.35 16.35a1.5 1.5 0 0 1 0 2.12L18.83 41.41a1 1 0 0 1-1.42 0L14.6 38.6a1 1 0 0 1 0-1.42L27.76 24Z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={159}
              height={17}
              fill="none"
              viewBox="0 0 159 17"
              style={{ position: "absolute", bottom: 0 }}
            >
              <path
                fill="#0BE09B"
                fillOpacity="0.12"
                d="M.5 0h158l-5.838 12.406A8 8 0 0 1 145.423 17H13.577a8 8 0 0 1-7.239-4.594z"
              />
            </svg>
          </div>
          {isLoggedIn ? (
            <div
              className="flex items-center justify-between"
              style={{ marginTop: 11 }}
              onClick={() => setShowLogout(true)}
            >
              <div className="flex gap-[12px]">
                <div
                  data-test-tag="tux-avatar"
                  className="rounded-full"
                  style={{
                    backgroundImage: `url("${user.avatar}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    width: 32,
                    height: 32,
                  }}
                >
                  <div
                    className="rounded-full border border-solid border-color-LineSecondary box-border"
                    style={{ width: 32, height: 32 }}
                  />
                </div>
                <div className="flex items-center gap-[8px]">
                  <div
                    data-e2e="tiktok-user-account-name"
                    className="P1-Medium truncate"
                    style={{ maxWidth: "calc(-173px + 100vw)" }}
                  >
                    {user.idTiktok}
                  </div>
                  <div className="h-[14px] background-color-BGHover w-[1px]" />
                  <div className="flex items-center">
                    <svg
                      viewBox="0 0 48 48"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <circle cx={24} cy={24} r={22} fill="#FBD32C" />
                      <circle cx={24} cy={24} r={17} fill="#F9BA10" />
                      <path
                        d="M40.93 25.5a17 17 0 1 0-33.87 0 17 17 0 0 1 33.87 0Z"
                        fill="#F6A811"
                      />
                      <path
                        d="M33 19a5.03 5.03 0 0 1-5.06-5h-3.38v13.61a3.07 3.07 0 0 1-3.1 3.06c-1.7 0-3.09-1.37-3.09-3.06a3.07 3.07 0 0 1 3.94-2.94v-3.4A6.43 6.43 0 0 0 15 27.6c0 3.54 2.9 6.4 6.47 6.4a6.43 6.43 0 0 0 6.47-6.39v-6.94A8.47 8.47 0 0 0 33 22.33V19Z"
                        fill="#fff"
                      />
                    </svg>
                    <div
                      data-e2e="wallet-coins-balance"
                      className="P3-Regular text-color-TextTertiary ms-2 whitespace-nowrap"
                    >
                      {`${formatNumberToCurrency(user.balance)} ${
                        user.balance > 0 ? " - chưa cập nhật vào app" : ""
                      }`}
                    </div>
                  </div>
                </div>
              </div>
              <svg
                fill="currentColor"
                fontSize="18px"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
              >
                <path d="M34.7 20.7a1 1 0 0 1 0 1.42l-9.29 9.3a2 2 0 0 1-2.82 0l-9.3-9.3a1 1 0 0 1 0-1.41l1.42-1.42a1 1 0 0 1 1.41 0L24 27.17l7.88-7.88a1 1 0 0 1 1.41 0l1.42 1.42Z" />
              </svg>
            </div>
          ) : (
            <div
              className="flex gap-[12px] items-center"
              style={{ marginTop: 11 }}
              onClick={() => setShowLogin(true)}
            >
              <svg
                fontSize="32px"
                viewBox="0 0 48 48"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
              >
                <g clipPath="url(#Icon_Color-Default_Avatar_svg__a)">
                  <circle cx={24} cy={24} r={24} fill="#D0D1D3" />
                  <circle
                    cx={24}
                    cy={22}
                    r={9}
                    fill="#fff"
                    fillOpacity="0.75"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.28 42.16a18 18 0 0 1 31.37 0A23.9 23.9 0 0 1 23.97 48c-6 0-11.48-2.2-15.69-5.84Z"
                    fill="#fff"
                    fillOpacity="0.75"
                  />
                </g>
                <defs>
                  <clipPath id="Icon_Color-Default_Avatar_svg__a">
                    <path fill="#fff" d="M0 0h48v48H0z" />
                  </clipPath>
                </defs>
              </svg>
              <div className="P1-Medium" data-e2e="account-non-login-ui">
                Đăng nhập
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="m-[8px] background-color-BGPrimary radius-containerslevel0-small">
        <h2
          data-e2e="wallet-buy-coins-title"
          className="px-[16px] py-[12px] text-color-TextPrimary P1-Medium"
        >
          Gói nạp xu
        </h2>
        <div
          role="separator"
          className="flex flex-shrink-0 box-border tux-separator--horizontal tux-separator--medium"
          style={{
            backgroundColor: "rgba(22, 24, 35, 0.2)",
            transformOrigin: "center center",
          }}
        />
        <div className="py-[10px] px-[16px]">
          <div className="flex items-center recharge-advantage-_aRA_3">
            <strong
              data-e2e="wallet-recharge-advantage-tip"
              className="text-color-Primary P3-Regular"
            >
              Hệ thống xử lý thẻ cào nhanh chóng và cộng xu trong vòng 3 giây
              <div className="tux-tooltip-reference">
                <span style={{ position: "relative", top: 2 }}>
                  <svg
                    fill="currentColor"
                    fontSize="14px"
                    color="rgba(0, 0, 0, 0.32)"
                    viewBox="0 0 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                  >
                    <path d="M24 6a18 18 0 1 0 0 36 18 18 0 0 0 0-36ZM2 24a22 22 0 1 1 44 0 22 22 0 0 1-44 0Zm25-8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V23a1 1 0 0 0-1-1h-2Z" />
                  </svg>
                </span>
              </div>
            </strong>
          </div>
          <ol
            data-e2e="wallet-coins-packages"
            className="grid p-0 gap-[8px] my-4"
            style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
          >
            {packages.map((obj, index) => (
              <li
                style={{ listStyleType: "none", overflow: "hidden" }}
                onClick={() => handleChangePackageSelected(obj)}
              >
                <div
                  data-e2e="wallet-package-selected"
                  className={`radius-containerslevel0-small w-full h-[62px] flex flex-col justify-center items-center background-color-BGBrand ${
                    packageSelected.amount === obj.amount
                      ? "border border-color-Primary border-solid"
                      : "border border-gray-200"
                  }`}
                >
                  <div className="flex gap-2 justify-center items-center">
                    <svg
                      fontSize="16px"
                      viewBox="0 0 48 48"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <circle cx={24} cy={24} r={22} fill="#FBD32C" />
                      <circle cx={24} cy={24} r={17} fill="#F9BA10" />
                      <path
                        d="M40.93 25.5a17 17 0 1 0-33.87 0 17 17 0 0 1 33.87 0Z"
                        fill="#F6A811"
                      />
                      <path
                        d="M33 19a5.03 5.03 0 0 1-5.06-5h-3.38v13.61a3.07 3.07 0 0 1-3.1 3.06c-1.7 0-3.09-1.37-3.09-3.06a3.07 3.07 0 0 1 3.94-2.94v-3.4A6.43 6.43 0 0 0 15 27.6c0 3.54 2.9 6.4 6.47 6.4a6.43 6.43 0 0 0 6.47-6.39v-6.94A8.47 8.47 0 0 0 33 22.33V19Z"
                        fill="#fff"
                      />
                    </svg>
                    <b data-e2e="wallet-package-coin-num-0" className="H3-Bold">
                      {formatNumberToCurrency(obj.coins)}
                    </b>
                  </div>
                  <span className="text-yellow-700 text-[10px]">
                    +{formatNumberToCurrency(obj.promotions)}
                  </span>
                  <div
                    data-e2e="wallet-package-price-0"
                    className="P3-Regular text-color-TextTertiaryAlt"
                  >
                    ₫{formatNumberToCurrency(obj.amount)}
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div
        className="mx-[8px] rounded-[8px] bg-gradient-to-r from-[rgb(229,252,255)] to-[rgb(177,232,255)] mb-[8px] px-[16px] py-[12px] flex flex-col gap-[20px] class sagasslash"
        // onClick={() => navivate("/sagesslash")}
      >
        <div
          className="flex justify-between text-color-UIText1 gap-[8px] overflow-hidden items-center"
          style={{ height: 56 }}
          onClick={() => !isLoggedIn && setShowLogin(true)}
        >
          <div className="flex items-center">
            <img
              alt="promotion image"
              src="https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/unified-wallet/resource/image/promotion-banner-unlock.12ec89e0.png"
              style={{
                maxWidth: 56,
                maxHeight: 56,
                marginRight: 12,
                marginLeft: 0,
              }}
            />
            <div className="flex flex-col gap-[2px]">
              <div className="flex items-center">
                <span className="P1-Medium">{getContentGift()}</span>
              </div>
              <div className="P3-Regular text-color-UIText2">
                <CountdownTimer />
              </div>
            </div>
          </div>
          <svg
            fill="currentColor"
            className="flip-rtl text-color-UIText3"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
          >
            <path d="M28.74 24 15.08 10.33a1 1 0 0 1 0-1.41l1.84-1.84a1 1 0 0 1 1.41 0L34.54 23.3a1 1 0 0 1 0 1.42l-16.2 16.21a1 1 0 0 1-1.42 0l-1.84-1.84a1 1 0 0 1 0-1.41L28.74 24Z" />
          </svg>
        </div>
        <AddToHomeScreen />
      </div>
      <div className="mx-[8px] rounded-[8px] background-color-BGPrimary">
        <div
          data-e2e="wallet-exchange-entrance"
          className="flex justify-between items-center px-[16px] py-[12px] text-color-LineSecondary2"
        >
          <div>
            <h2 className="P1-Medium mb-[4px] text-color-TextPrimary">
              Nhiệm vụ nhận xu
            </h2>
            <div
              className="P3-Regular text-color-TextPrimary"
              data-e2e="wallet-exchange-balance"
            >
              {isLoggedIn ? (
                <span className="flex items-center">
                  {giftUnUsed?.coins > user.balance_base
                    ? `Bạn đã hoàn thành: ${formatNumberToCurrency(
                        user.balance_base
                      )}/
                ${formatNumberToCurrency(giftUnUsed?.coins)}`
                    : "Nạp 1 thẻ cào bất kỳ để hoàn thành nhiệm vụ"}
                  <svg
                    width="12px"
                    data-e2e
                    height="12px"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ verticalAlign: "-0.15em" }}
                  >
                    <circle cx={24} cy={24} r={22} fill="#FFEC9B" />
                    <circle cx={24} cy={24} r={17} fill="#FACE15" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M40.9347 25.5C40.9779 25.0058 41 24.5055 41 24C41 14.6112 33.3888 7 24 7C14.6112 7 7 14.6112 7 24C7 24.5055 7.02206 25.0058 7.06527 25.5C7.82466 16.8137 15.1166 10 24 10C32.8834 10 40.1753 16.8137 40.9347 25.5Z"
                      fill="#FABC15"
                    />
                    <path
                      d="M33 19C30.2041 19 27.9375 16.7614 27.9375 14H24.5625V27.6111C24.5625 29.2986 23.1774 30.6667 21.4688 30.6667C19.7601 30.6667 18.375 29.2986 18.375 27.6111C18.375 25.9236 19.7601 24.5556 21.4688 24.5556C21.722 24.5556 21.9659 24.5853 22.1981 24.6406C22.2365 24.6497 22.2747 24.6596 22.3125 24.6701V21.2763C22.0358 21.2406 21.7541 21.2222 21.4688 21.2222C17.8962 21.2222 15 24.0826 15 27.6111C15 31.1396 17.8962 34 21.4688 34C25.0413 34 27.9375 31.1396 27.9375 27.6111V20.6673C29.3477 21.7134 31.1005 22.3333 33 22.3333V19Z"
                      fill="#FEF5CD"
                    />
                  </svg>
                </span>
              ) : (
                "Bạn cần đăng nhập để xem nhiệm vụ"
              )}
            </div>
          </div>
        </div>
      </div>
      {giftUnUsed && isLoggedIn && user.balance_base > 0 && (
        <div className="mx-[8px] rounded-[8px] background-color-BGPrimary">
          <div
            data-e2e="wallet-exchange-entrance"
            className="flex justify-between items-center px-[16px] py-[12px] text-color-LineSecondary2 mt-2"
          >
            <div>
              <h2 className="P1-Medium mb-[4px] !text-red-600">
                Thông báo quan trọng
              </h2>
              <div
                className="P3-Regular text-color-TextQuaternary"
                data-e2e="wallet-exchange-balance"
              >
                <span className="flex items-center text-black">
                  Để đảm bảo tính công bằng và minh bạch, bạn cần hoàn thành
                  nhiệm vụ trước khi TikTok cập nhật xu vào tài khoản của bạn.
                  Hãy nhanh tay thực hiện để không bỏ lỡ cơ hội nhận thưởng
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoggedIn && (
        <div className="mx-[8px] mt-4 border rounded-[8px] background-color-BGPrimary">
          <div className="mx-[8px] max-h-[300px] overflow-auto py-3">
            <TransactionHistory transactions={transactions} />
          </div>
        </div>
      )}

      <div className="mx-[8px] mt-4">
        <ChatBox />
      </div>

      <div className="mt-[24px] Headline-Medium flex content-center items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="69"
          height="16"
          fill="none"
          viewBox="0 0 69 16"
        >
          <path
            fill="#161823"
            fill-opacity="0.1"
            d="M28.207 6.681a1.399 1.399 0 1 0-.001-2.798 1.399 1.399 0 0 0 0 2.798M40.594 3.883h8.879l-.797 2.483h-2.449v9.181999999999999h-2.81V6.364h-2.492l-.33.006zM26.813 7.848v-.253h2.781l.001.253.003 6.968-.007.731H26.83v-.396zM31.047 4.4v-.55h2.75v5.433000000000001l2.781-2.707h3.316l-3.482 3.381 3.9 5.583H37.25l-2.603-3.873-.825.798v3.076l-2.767-.006v-.248zM58.77 4.4v-.55h2.75v.479l.007 4.954 2.775-2.706h3.316l-3.48 3.385 3.906 5.583h-3.061l-2.606-3.873-.824.798v3.076h-2.766v-.248zM48.063 10.818a4.781 4.781 0 1 1 9.197 1.859 4.8 4.8 0 0 1-1.757 2.145 4.77 4.77 0 0 1-2.65.808 4.78 4.78 0 0 1-3.4-1.404 4.8 4.8 0 0 1-1.038-1.564 4.8 4.8 0 0 1-.352-1.844m6.95 0c0-1.292-.89-2.303-2.179-2.3-1.29 0-2.162 1.012-2.162 2.304s.866 2.313 2.162 2.311c1.297 0 2.18-1.028 2.179-2.32zM17.496 3.883h8.674l-.797 2.483H23.12v9.177h-2.8V6.365l-2.822.006zM14.686 3.843c-.981-.03-1.87-.5-2.54-1.128A3.69 3.69 0 0 1 11.006 0H8.276v10.53c0 1.721-1.031 2.734-2.296 2.734a2.3 2.3 0 0 1-1.77-.803 2.3 2.3 0 0 1-.468-.879 2.29 2.29 0 0 1 .318-1.917 2.29 2.29 0 0 1 2.662-.866v-2.78a3.4 3.4 0 0 0-.742-.079 5.025 5.025 0 0 0-4.644 3.104 5.034 5.034 0 0 0 3.661 6.86 5.023 5.023 0 0 0 6.007-4.934V5.302c1.058.733 2.323 1.078 3.674 1.103z"
          ></path>
        </svg>
      </div>
    </>
  );
}
