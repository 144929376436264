import {
  BALANCE_SUCCESS,
  GET_TRANSACTIONS,
  GIFT_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  PACKAGES_SUCCESS,
  TRANSACTION_SUCCESS,
} from "../actions/authActions";

const initialState = {
  isLoggedIn: false,
  user: {},
  packages: [],
  gifts: [],
  giftUnused: null,
  transactions: [],
  loadingTransactions: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    case BALANCE_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...state.user,
          balance: action.payload.balance,
          balance_base: action.payload.balance_base,
        },
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        loadingTransactions: true,
      };
    case TRANSACTION_SUCCESS:
      return {
        ...state,
        loadingTransactions: false,
        transactions: action.payload,
      };
    case PACKAGES_SUCCESS:
      return {
        ...state,
        packages: action.payload,
      };
    case GIFT_SUCCESS:
      return {
        ...state,
        gifts: action.payload,
        giftUnUsed: action.payload.find((item) => !item.statusReceived),
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
      };
    default:
      return state;
  }
};

export default authReducer;
