export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const BALANCE_SUCCESS = "BALANCE_SUCCESS";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const PACKAGES_SUCCESS = "PACKAGES_SUCCESS";
export const GIFT_SUCCESS = "GIFT_SUCCESS";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";

export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});
export const getTransactions = (payload) => ({
  type: GET_TRANSACTIONS,
  payload
});
export const getBalanceSuccess = (balance) => ({
  type: BALANCE_SUCCESS,
  payload: balance,
});
export const getTransactionSuccess = (trans) => ({
  type: TRANSACTION_SUCCESS,
  payload: trans,
});

export const getGiftSuccess = (gifts) => ({
  type: GIFT_SUCCESS,
  payload: gifts,
});

export const getPackagesSuccess = (packages) => ({
  type: PACKAGES_SUCCESS,
  payload: packages,
});

export const logout = () => ({
  type: LOGOUT,
});
