import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/authActions";

const Header = ({ setShowLogin }) => {
  const [showSuggestSearch, setShowSuggestSearch] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showSuggestSearch) {
    }
  }, [showSuggestSearch]);

  const handleSearchInputBlur = (focus) => {
    setShowSuggestSearch(focus);
  };

  const handleShowLogin = () => {
    setShowLogin(true);
  };

  const handleLogout = () => {
    // Logout logic here
    dispatch(logout());
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userData")
  };

  return (
    <div
      id="app-header"
      className="css-iozudi-DivHeaderContainer e10win0d0"
      style={{ display: "flex" }}
    >
      <div className="css-1gd9bb4-DivHeaderWrapperMain e10win0d1">
        <div className="css-t4zcgw-DivHeaderLeftContainer en2lce51">
          <Link
            to="/"
            data-e2e="tiktok-logo"
            className="e1an6zpe0 css-1ooyga3-ALink-StyledLinkLogo er1vbsz1"
            aria-label="Go to TikTok For You feed"
            style={{ position: "relative" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 118 42"
              width={118}
              height={42}
              alt="TikTok"
            >
              <path
                fill="#25F4EE"
                d="M9.875 16.842v-1.119A8.836 8.836 0 008.7 15.64c-4.797-.006-8.7 3.9-8.7 8.707a8.706 8.706 0 003.718 7.135A8.675 8.675 0 011.38 25.55c0-4.737 3.794-8.598 8.495-8.707z"
              />
              <path
                fill="#25F4EE"
                d="M10.086 29.526c2.14 0 3.89-1.707 3.967-3.83l.006-18.968h3.463a6.78 6.78 0 01-.11-1.202h-4.726l-.006 18.969a3.978 3.978 0 01-3.967 3.829 3.93 3.93 0 01-1.846-.46 3.949 3.949 0 003.22 1.662zM23.992 13.166v-1.055a6.506 6.506 0 01-3.583-1.068 6.571 6.571 0 003.583 2.123z"
              />
              <path
                fill="#FE2C55"
                d="M20.409 11.043a6.54 6.54 0 01-1.616-4.315h-1.265a6.557 6.557 0 002.88 4.316zM8.706 20.365a3.98 3.98 0 00-3.973 3.976c0 1.528.869 2.858 2.134 3.523a3.936 3.936 0 01-.754-2.321 3.98 3.98 0 013.973-3.976c.409 0 .805.07 1.175.185v-4.833a8.837 8.837 0 00-1.175-.083c-.07 0-.134.006-.204.006v3.708a3.999 3.999 0 00-1.176-.185z"
              />
              <path
                fill="#FE2C55"
                d="M23.992 13.166v3.676c-2.453 0-4.727-.786-6.58-2.116v9.621c0 4.802-3.902 8.714-8.706 8.714a8.669 8.669 0 01-4.988-1.579 8.69 8.69 0 006.368 2.781c4.797 0 8.707-3.906 8.707-8.714v-9.621a11.25 11.25 0 006.579 2.116v-4.73c-.48 0-.94-.052-1.38-.148z"
              />
              <path
                fill="black"
                d="M17.413 24.348v-9.622a11.251 11.251 0 006.58 2.116v-3.676a6.571 6.571 0 01-3.584-2.123 6.61 6.61 0 01-2.888-4.315H14.06l-.006 18.968a3.978 3.978 0 01-3.967 3.83A3.99 3.99 0 016.86 27.87a3.991 3.991 0 01-2.133-3.523A3.98 3.98 0 018.7 20.372c.409 0 .805.07 1.175.185v-3.708c-4.701.103-8.495 3.964-8.495 8.701 0 2.29.888 4.373 2.338 5.933a8.669 8.669 0 004.988 1.58c4.798 0 8.707-3.913 8.707-8.714zM30.048 13.179h14.774l-1.354 4.232h-3.832v15.644h-4.778V17.41l-4.804.006-.006-4.238zM69.032 13.179h15.12l-1.355 4.232h-4.17v15.644h-4.785V17.41l-4.804.006-.006-4.238zM45.73 19.502h4.733v13.553h-4.708l-.026-13.553zM52.347 13.128h4.733v9.257l4.689-4.61h5.646l-5.934 5.76 6.644 9.52h-5.213l-4.433-6.598-1.405 1.362v5.236H52.34V13.128h.006zM102.49 13.128h4.734v9.257l4.688-4.61h5.647l-5.934 5.76 6.643 9.52h-5.206l-4.433-6.598-1.405 1.362v5.236h-4.734V13.128zM48.093 17.954a2.384 2.384 0 002.382-2.384 2.384 2.384 0 10-2.382 2.384z"
              />
              <path
                fill="#25F4EE"
                d="M83.544 24.942a8.112 8.112 0 017.474-8.087 8.748 8.748 0 00-.709-.026c-4.478 0-8.106 3.631-8.106 8.113 0 4.482 3.628 8.113 8.106 8.113.21 0 .498-.013.71-.026-4.178-.326-7.475-3.823-7.475-8.087z"
              />
              <path
                fill="#FE2C55"
                d="M92.858 16.83c-.217 0-.505.012-.716.025a8.111 8.111 0 017.468 8.087 8.112 8.112 0 01-7.468 8.087c.211.02.499.026.716.026 4.478 0 8.106-3.631 8.106-8.113 0-4.482-3.628-8.113-8.106-8.113z"
              />
              <path
                fill="black"
                d="M91.58 28.887a3.94 3.94 0 01-3.94-3.945 3.94 3.94 0 117.882 0c0 2.18-1.77 3.945-3.942 3.945zm0-12.058c-4.477 0-8.106 3.631-8.106 8.113 0 4.482 3.629 8.113 8.106 8.113 4.478 0 8.106-3.631 8.106-8.113 0-4.482-3.628-8.113-8.106-8.113z"
              />
            </svg>
            <strong>TikTok</strong>
          </Link>
        </div>
        <div className="css-3z2zs6-DivHeaderCenterContainer en2lce50">
          <div className="css-1asq5wp-DivSearchFormContainer e1hi1cmj0">
            <form
              data-e2e="search-box"
              className="search-input css-960d5u-FormElement e14ntknm0"
            >
              <input
                placeholder="Search"
                name="q"
                type="search"
                autoComplete="off"
                role="combobox"
                aria-controls
                aria-label="Search"
                aria-expanded="false"
                aria-autocomplete="list"
                data-e2e="search-user-input"
                className="css-1yf5w3n-InputElement e14ntknm3"
                onFocus={() => handleSearchInputBlur(true)}
                onBlur={() => handleSearchInputBlur(false)}
              />
              <span className="css-hck1rr-SpanSpliter e14ntknm6" />
              <button
                data-e2e="search-box-button"
                aria-label="Search"
                className="css-16dy42q-ButtonSearch e14ntknm7"
                type="button"
              >
                <div className="css-17iic05-DivSearchIconContainer e14ntknm8">
                  <svg
                    width={24}
                    data-e2e
                    height={24}
                    viewBox="0 0 48 48"
                    fill="rgba(22, 24, 35, .34)"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22 10C15.3726 10 10 15.3726 10 22C10 28.6274 15.3726 34 22 34C28.6274 34 34 28.6274 34 22C34 15.3726 28.6274 10 22 10ZM6 22C6 13.1634 13.1634 6 22 6C30.8366 6 38 13.1634 38 22C38 25.6974 36.7458 29.1019 34.6397 31.8113L43.3809 40.5565C43.7712 40.947 43.7712 41.5801 43.3807 41.9705L41.9665 43.3847C41.5759 43.7753 40.9426 43.7752 40.5521 43.3846L31.8113 34.6397C29.1019 36.7458 25.6974 38 22 38C13.1634 38 6 30.8366 6 22Z"
                    />
                  </svg>
                </div>
              </button>
              <div className="css-1mdii59-DivInputBorder e14ntknm1" />
            </form>
            {showSuggestSearch && (
              <ul
                data-e2e="search-transfer"
                id="header-transfer"
                className="css-1wubcpo-UlContainer e1ysk6kp0"
              >
                <div
                  data-e2e="search-transfer-guess-search-title"
                  className="css-94x12r-DivHeader e1ysk6kp1"
                >
                  You may like
                </div>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={suggestion.group_id}
                    data-e2e="search-transfer-guess-search-item"
                    id={`transfer-list-item-${index}`}
                    className="css-dh0xqw-LiItemContainer e1ysk6kp2"
                  >
                    <div className="css-qtq8f2-DivMark e1ysk6kp13">
                      <div className="css-1ecigqx-DivMarkDot e1ysk6kp14" />
                    </div>
                    <h4 className="css-18wh5nv-H4ItemText e1ysk6kp3">
                      {suggestion.word}
                    </h4>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {isLoggedIn ? (
          <div className="css-q8q040-DivHeaderRightContainer e13wiwn60">
            <div className="css-32faz3">
              <div
                data-e2e="top-dm-icon"
                className="css-9j9jz0-DivMessageIconContainer e1nx07zo0"
              >
                <div
                  aria-label="Open messages"
                  className="css-22xkqc-StyledLink er1vbsz0"
                >
                  <span>
                    <svg
                      className="css-y48l9g-StyledIcon e1nx07zo1"
                      width="1em"
                      data-e2e
                      height="1em"
                      viewBox="0 0 48 48"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.17877 7.17357C2.50304 6.45894 3.21528 6 4.00003 6H44C44.713 6 45.372 6.37952 45.7299 6.99615C46.0877 7.61278 46.0902 8.37327 45.7365 8.99228L25.7365 43.9923C25.3423 44.6821 24.5772 45.0732 23.7872 44.9886C22.9972 44.9041 22.3321 44.3599 22.0929 43.6023L16.219 25.0017L2.49488 9.31701C1.97811 8.72642 1.85449 7.88819 2.17877 7.17357ZM20.377 24.8856L24.531 38.0397L40.5537 10H8.40757L18.3918 21.4106L30.1002 14.2054C30.5705 13.9159 31.1865 14.0626 31.4759 14.533L32.5241 16.2363C32.8136 16.7066 32.6669 17.3226 32.1966 17.612L20.377 24.8856Z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div
              tabIndex={0}
              role="button"
              aria-expanded="false"
              aria-label="Inbox
  440 unread notifications"
              data-e2e="inbox-icon"
              className="css-1deszxq-DivHeaderInboxContainer e18kkhh40"
            >
              <span>
                <svg
                  className="css-1g0p6jv-StyledInboxIcon e18kkhh41"
                  width={32}
                  data-e2e
                  height={32}
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.0362 21.3333H18.5243L15.9983 24.4208L13.4721 21.3333H7.96047L7.99557 8H24.0009L24.0362 21.3333ZM24.3705 23.3333H19.4721L17.2883 26.0026C16.6215 26.8176 15.3753 26.8176 14.7084 26.0026L12.5243 23.3333H7.62626C6.70407 23.3333 5.95717 22.5845 5.9596 21.6623L5.99646 7.66228C5.99887 6.74352 6.74435 6 7.66312 6H24.3333C25.2521 6 25.9975 6.7435 26 7.66224L26.0371 21.6622C26.0396 22.5844 25.2927 23.3333 24.3705 23.3333ZM12.6647 14C12.2965 14 11.998 14.2985 11.998 14.6667V15.3333C11.998 15.7015 12.2965 16 12.6647 16H19.3313C19.6995 16 19.998 15.7015 19.998 15.3333V14.6667C19.998 14.2985 19.6995 14 19.3313 14H12.6647Z"
                  />
                </svg>
              </span>
            </div>
            <div
              id="header-more-menu-icon"
              tabIndex={0}
              role="button"
              aria-label="Open settings menu"
              aria-expanded="false"
              aria-haspopup="true"
              aria-controls
              data-e2e="profile-icon"
              className="css-15iwytd-DivProfileContainer efubjyv0"
              style={{
                backgroundImage: `url("${user?.avatar}")`,
              }}
            >
              <div
                id="header-setting-popup-list"
                tabIndex={-1}
                className="css-uoaz8g-DivContainer e2ipgxl0"
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  right: "-12px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="rgba(255, 255, 255, 1)"
                  viewBox="0 0 24 8"
                  width="1em"
                  height="1em"
                  className="css-qobtlf-StyledTopArrow e2ipgxl1"
                  style={{ right: 15 }}
                >
                  <path d="M0 8c7 0 10-8 12-8s5 8 12 8z" />
                </svg>
                <ul
                  data-e2e="profile-popup"
                  id="header-setting-popup-list"
                  role="listbox"
                  className="css-e30u3h-UlPopupContainer exws2ct0"
                >
                  <li
                    data-e2e="logout-entrance"
                    tabIndex={0}
                    role="option"
                    className="css-xuc8vq-LiItemWrapper exws2ct1"
                  >
                    <div className="css-6y9y4i-DivSettingItem exws2ct4">
                      <svg
                        className="css-2jg6he"
                        width="1em"
                        data-e2e
                        height="1em"
                        viewBox="0 0 48 48"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M24.0003 7C20.1343 7 17.0003 10.134 17.0003 14C17.0003 17.866 20.1343 21 24.0003 21C27.8663 21 31.0003 17.866 31.0003 14C31.0003 10.134 27.8663 7 24.0003 7ZM13.0003 14C13.0003 7.92487 17.9252 3 24.0003 3C30.0755 3 35.0003 7.92487 35.0003 14C35.0003 20.0751 30.0755 25 24.0003 25C17.9252 25 13.0003 20.0751 13.0003 14ZM24.0003 33C18.0615 33 13.0493 36.9841 11.4972 42.4262C11.3457 42.9573 10.8217 43.3088 10.2804 43.1989L8.32038 42.8011C7.77914 42.6912 7.4266 42.1618 7.5683 41.628C9.49821 34.358 16.1215 29 24.0003 29C31.8792 29 38.5025 34.358 40.4324 41.628C40.5741 42.1618 40.2215 42.6912 39.6803 42.8011L37.7203 43.1989C37.179 43.3088 36.6549 42.9573 36.5035 42.4262C34.9514 36.9841 29.9391 33 24.0003 33Z"
                        />
                      </svg>

                      <span>@{user.idTiktok}</span>
                    </div>
                  </li>
                  <li
                    data-e2e="logout-entrance"
                    tabIndex={0}
                    role="option"
                    className="css-xuc8vq-LiItemWrapper exws2ct1"
                    onClick={handleLogout}
                  >
                    <div className="css-6y9y4i-DivSettingItem exws2ct4">
                      <svg
                        className="css-2jg6he"
                        width="1em"
                        data-e2e
                        height="1em"
                        viewBox="0 0 48 48"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M24.1716 26L7 26C6.44771 26 6 25.5523 6 25L6 23C6 22.4477 6.44771 22 7 22L24.1716 22L20.2929 18.1213C19.9024 17.7308 19.9024 17.0976 20.2929 16.7071L21.7071 15.2929C22.0976 14.9024 22.7308 14.9024 23.1213 15.2929L30.4142 22.5858C31.1953 23.3668 31.1953 24.6332 30.4142 25.4142L23.1213 32.7071C22.7308 33.0976 22.0976 33.0976 21.7071 32.7071L20.2929 31.2929C19.9024 30.9024 19.9024 30.2692 20.2929 29.8787L24.1716 26ZM36 43L27 43C26.4477 43 26 42.5523 26 42L26 40C26 39.4477 26.4477 39 27 39L36 39C37.1046 39 38 38.1046 38 37L38 11C38 9.89543 37.1046 9 36 9L27 9C26.4477 9 26 8.55228 26 8L26 6C26 5.44771 26.4477 5 27 5L36 5C39.3137 5 42 7.68629 42 11L42 37C42 40.3137 39.3137 43 36 43Z"
                        />
                      </svg>
                      <span>Log out</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="tiktok-q8q040-DivHeaderRightContainer e13wiwn60">
            <button
              type="button"
              data-e2e="top-login-button"
              id="header-login-button"
              className="e13wiwn61 tiktok-1fef8ew-Button-StyledLoginButton ehk74z00"
              onClick={handleShowLogin}
            >
              Đăng nhập
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
